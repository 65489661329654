<script setup lang="ts">
import { ReportingPeriodicity } from '@swipe4work/api-client-fetch'
import { useToast } from 'vue-toastification'

import { z } from 'zod'
import UpdatePasswordDialog from '@/pages/settings/dialogs/UpdatePasswordDialog.vue'
import { zodResolver } from '@primeuix/forms/resolvers/zod'
import { Form } from '@primevue/forms'

definePage({
  name: '/settings/UserProfile',
  path: '/instellingen/gebruikersprofiel',
  meta: {
    layout: 'false',
  },
})

const t = useI18n()
const toast = useToast()
const dialog = useDynamicDialog()
const taskHandler = useAsyncTaskHandler()

const settingsStore = useSettingsStore()
const authStore = useAuthStore()

const { employerProfile } = storeToRefs(authStore)

const reportingPeriodicityOptions = Object.keys(ReportingPeriodicity).map(key => ({
  label: t.t(`${key.toLocaleLowerCase()}`),
  value: key,
}))

async function openUpdatePasswordDialog() {
  dialog.open(UpdatePasswordDialog, {
    props: {
      dismissableMask: true,
      style: {
        width: 'fit-content',
        height: 'auto',
      },
    },
  })
}

async function onFormSubmit({ values, valid }: {
  values: any | undefined,
  valid: boolean
}) {
  if (!valid || !values) {
    toast.error('Er zijn nog validatiefouten in het formulier')

    return
  }

  await taskHandler
    .useOnSucceedTasks()
    .notifyWithToast(t.t('success'), t.t('settings.changesSaved'))
    .callback(async () => {
      await authStore.fetchEmployerProfile()
    })
    .endUse()
    .resolve(() => settingsStore.updateProfile(values))
}

const resolver = zodResolver(
  z.object({
    firstName: z.string({
      required_error: 'Voornaam is verplicht',
    }),
    lastName: z.string({
      required_error: 'Achternaam is verplicht',
    }),
    reportingPeriodicity: z.nativeEnum(ReportingPeriodicity),
  }).required({
    firstName: true,
    lastName: true,
    reportingPeriodicity: true,
  }),
)
</script>

<template>
  <Form
    :resolver
    @submit="onFormSubmit"
    class="max-w-2xl w-full py-2 space-y-3"
    :initial-values="employerProfile"
  >
    <h6>
      Profiel van {{ employerProfile?.firstName }} {{ employerProfile?.lastName }}
    </h6>
    <div class="grid grid-cols-2 gap-x-2">
      <FormField
        v-slot="$field"
        name="firstName"
        class="flex flex-col gap-1"
      >
        <div>
          <IftaLabel>
            <InputText class="w-full" />
            <label for="firstName">Voornaam</label>
          </IftaLabel>

          <Message
            v-if="$field?.invalid"
            severity="error"
            size="small"
            variant="simple"
          >
            {{ $field.error?.message }}
          </Message>
        </div>
      </FormField>

      <FormField
        v-slot="$field"
        name="lastName"
        class="flex flex-col gap-1"
      >
        <IftaLabel>
          <InputText class="w-full" />
          <label for="lastName">Achternaam</label>
        </IftaLabel>
        <Message
          v-if="$field?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $field.error?.message }}
        </Message>
      </FormField>
    </div>

    <IftaLabel>
      <InputText class="w-full" :value="employerProfile?.email" :disabled="true" />
      <label for="email">E-mail</label>
    </IftaLabel>

    <FormField
      v-slot="$field"
      name="reportingPeriodicity"
      class="flex flex-col gap-1"
    >
      <IftaLabel>
        <Select
          class="w-full"
          :options="reportingPeriodicityOptions"
          option-value="value"
          option-label="label"
        />
        <label for="reportingPeriodicity">E-mail Rapportageperiode</label>
      </IftaLabel>
      <small>
        De frequentie waarmee je rapportages ontvangt; met betrekking tot de te beoordelen kandidaten.
      </small>
      <Message
        v-if="$field?.invalid"
        severity="error"
        size="small"
        variant="simple"
      >
        {{ $field.error?.message }}
      </Message>
    </FormField>

    <div class="flex gap-x-2 pt-2">
      <Button type="submit" :label="$t('save')" />
      <Button outlined label="Wachtwoord wijzigen" @click="openUpdatePasswordDialog" />
    </div>
  </Form>
</template>
