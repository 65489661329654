<script setup lang="ts">
import NProgress from 'nprogress'

import { object, string } from 'zod'
import { useToast } from 'vue-toastification'
import { useForm } from '@/modules/form'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'

const upgradeStore = useUpgradeStore()
const { leadProfile } = storeToRefs(upgradeStore)
const router = useRouter()

definePage({
  path: '/upgrade/account',
  meta: {
    permissionResource: 'Lead',
  },
})

const t = useI18n()
const toast = useToast()

if (leadProfile.value?.hasAccount) {
  toast.info(t.t('leads.upgrade.account.alreadyHasAccount', { email: leadProfile.value.email }))
  router.replace({ name: '/upgrade/billing' })
}

const registerModel = reactive({
  employerFirstName: leadProfile.value?.firstName,
  employerLastName: leadProfile.value?.lastName,
  email: leadProfile.value?.email,
  password: '',
  confirmPassword: '',
})

const registerScheme = object({
  employerFirstName: string().min(1, t.t('leads.upgrade.account.firstNameRequired')),
  employerLastName: string().min(1, t.t('leads.upgrade.account.lastNameRequired')),
  email: string().email(t.t('leads.upgrade.account.emailNotValid')),
  password: string().min(8, t.t('leads.upgrade.account.passwordRequired')),
  confirmPassword: string().min(8, t.t('leads.upgrade.account.passwordRequired')),
})

const form = useForm(registerModel, registerScheme)

const passwordsNotEqual = ref(false)
const authStore = useAuthStore()

async function register() {
  const entity = form.validate()
  if (!entity) {
    return
  }
  passwordsNotEqual.value = entity.password !== entity.confirmPassword

  if (passwordsNotEqual.value) {
    return
  }

  NProgress.start()
  const response = await upgradeStore.createFirebaseAccount(
    {
      firstName: entity.employerFirstName,
      lastName: entity.employerLastName,
      password: entity.password,
      email: entity.email,
    },
  )
  if (response) {
    try {
      await authStore.logout()
      const firebaseAuthService = await authStore.getAuthService<FirebaseAuthService>(FirebaseAuthService, authStore.firebaseAuth)

      await firebaseAuthService.loginWithToken(response.token)

      await Promise.all([ authStore.fetchEmployerAccess(), authStore.fetchEmployerProfile() ])
    } catch (error) {
      console.error(error)
      authStore.setAuthState(AuthState.AuthenticationError)
    }
    upgradeStore.goToPage(1)
  }
  NProgress.done()
}

// Watchers to update passwordsNotEqual when the password fields change
watch(
  () => registerModel.password,
  (newVal, oldVal) => {
    if (form.submitted.value && (oldVal !== '' || registerModel.confirmPassword !== '')) {
      passwordsNotEqual.value = newVal !== registerModel.confirmPassword
    }
  },
)

watch(
  () => registerModel.confirmPassword,
  (newVal, oldVal) => {
    if (form.submitted.value && (oldVal !== '' || registerModel.password !== '')) {
      passwordsNotEqual.value = newVal !== registerModel.password
    }
  },
)
</script>

<template>
  <main>
    <form @submit.prevent="register">
      <Card>
        <template #title>
          <h2>
            {{ $t("leads.upgrade.account.title") }}
          </h2>
        </template>
        <template #content>
          <div class="min-h-[30rem]">
            <div>{{ $t("leads.upgrade.account.subtitle") }}</div>
            <div class="mt-4">
              <label class="text-sm" for="email"> {{ $t("register.email") }} </label>
              <InputText
                id="email"
                v-model.trim="registerModel.email"
                class="w-100%"
                autocomplete="email"
              />
              <small v-if="form.hasError('email')" class="color-red-700">{{ form.errorMessage("email") }}</small>
            </div>
            <div class="mt-4">
              <label for="employerFirstName"> {{ $t("settings.firstName") }}* </label>
              <InputText
                id="employerFirstName"
                v-model.trim="registerModel.employerFirstName"
                class="w-100%"
                required="true"
                autofocus
                :class="{ 'p-invalid': form.hasError('registerModel.employerFirstName') }"
              />
              <small v-if="form.hasError('employerFirstName')" class="color-red-700">{{ form.errorMessage("employerFirstName") }}</small>
            </div>
            <div class="mt-4">
              <label for="employerLastName"> {{ $t("settings.lastName") }}* </label>
              <InputText
                id="employerLastName"
                v-model.trim="registerModel.employerLastName"
                class="w-100%"
                required="true"
                :class="{ 'p-invalid': form.hasError('registerModel.employerLastName') }"
              />
            </div>

            <div class="mt-4">
              <label class="block text-sm" for="password"> {{ $t("password") }} </label>
              <Password
                id="password"
                v-model="registerModel.password"
                toggle-mask
                :feedback="false"
                :required="true"
                :input-props="{ autocomplete: 'new-password', autofocus: true }"
                :class="{ 'p-invalid': form.hasError('registerModel.password') }"
              />
              <small v-if="form.hasError('password')" class="color-red-700">{{ form.errorMessage("password") }}</small>

              <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
            </div>
            <div class="mt-4">
              <label class="block text-sm" for="confirmPassword"> {{ $t("confirmPassword") }} </label>
              <Password
                id="confirmPassword"
                v-model="registerModel.confirmPassword"
                toggle-mask
                :feedback="false"
                :required="true"
                :input-props="{ autocomplete: 'new-password', autofocus: true }"
                :class="{ 'p-invalid': form.hasError('registerModel.confirmPassword') }"
              />
              <small v-if="form.hasError('confirmPassword')" class="color-red-700">{{ form.errorMessage("confirmPassword") }}</small>
              <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex justify-end">
            <Button
              v-click-async="register"
              rounded
              :label="$t('register.submit')"
            />
          </div>
        </template>
      </Card>
    </form>
  </main>
</template>
