<script setup lang="ts">
definePage({
  name: '/jobs/ai',
  meta: {
    layout: 'empty',
  },
})

await useStoreInitializer([ 'CompanyStore', 'StaticDataStore' ], [ 'DashboardStore', 'JobConverterStore' ])
</script>

<template>
  <router-view v-slot="{ Component }">
    <suspense>
      <component :is="Component" />
    </suspense>
  </router-view>
</template>
