/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobConverterResponseFromJSON, JobConverterResponseToJSON, } from './JobConverterResponse.js';
/**
 * Check if a given object implements the UpdateFlowRunRefinedOutputRequest interface.
 */
export function instanceOfUpdateFlowRunRefinedOutputRequest(value) {
    return true;
}
export function UpdateFlowRunRefinedOutputRequestFromJSON(json) {
    return UpdateFlowRunRefinedOutputRequestFromJSONTyped(json, false);
}
export function UpdateFlowRunRefinedOutputRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'runId': json['run_id'] == null ? undefined : json['run_id'],
        'refinedOutput': json['refined_output'] == null ? undefined : JobConverterResponseFromJSON(json['refined_output']),
    };
}
export function UpdateFlowRunRefinedOutputRequestToJSON(json) {
    return UpdateFlowRunRefinedOutputRequestToJSONTyped(json, false);
}
export function UpdateFlowRunRefinedOutputRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'run_id': value['runId'],
        'refined_output': JobConverterResponseToJSON(value['refinedOutput']),
    };
}
