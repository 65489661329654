<script setup lang="ts">
import InviteTeammateDialog from './dialogs/InviteTeammateDialog.vue'
import { useConfirm } from 'primevue/useconfirm'
import { EmployerClientType } from '@/modules/api-clients/api-clients'
import { DeleteEmployerTeammateType } from '@swipe4work/api-client-fetch'

definePage({
  name: '/settings/Teammates',
  path: '/instellingen/teamgenoten',
  meta: {
    layout: 'false',
  },
})

const companyStore = useCompanyStore()
const authStore = useAuthStore()
const { userId } = storeToRefs(authStore)
const { company } = storeToRefs(companyStore)
const settingsStore = useSettingsStore()
const { employers } = storeToRefs(settingsStore)

const taskHandler = useAsyncTaskHandler()
const t = useI18n()
const confirm = useConfirm()
const dialog = useDynamicDialog()

async function openInviteTeammateDialog() {
  dialog.open(InviteTeammateDialog, {
    props: {
      dismissableMask: true,
      style: {
        width: 'fit-content',
        height: 'auto',
      },
    },
  })
}

async function openDeleteTeammateDialog(type: DeleteEmployerTeammateType, userId: number) {
  confirm.require({
    target: document.body,
    modal: true,
    message: t.t('settings.teammates.deleteTeammateMessage'),
    header: t.t('settings.teammates.deleteTeammateTitle'),
    accept: async () => {
      await taskHandler.useClient('employerApi')
        .useOnSucceedTasks()
        .notifyWithToast(t.t('success'), t.t('settings.teammates.deleteTeammateSuccess'))
        .callback(() => settingsStore.fetchEmployersInCompany())
        .endUse()
        .resolve<EmployerClientType>(http => http.deleteTeammate({
          id: userId,
          type: type,
        }))
    },
    acceptLabel: t.t('confirm'),
    rejectLabel: t.t('cancel'),
  })
}

const initials = (name: string) => name.split(' ').map(n => n[0]).join('')
</script>

<template>
  <DataTable :value="employers" striped-rows>
    <template #header>
      <div class="flex items-center justify-between">
        <h5 class="max-sm:hidden">
          {{ $t('settings.teammates.table.title', { companyName: company?.name }) }}
        </h5>
        <Button
          :label="$t('settings.teammates.invite')"
          icon="bi bi-plus-lg"
          class="max-sm:w-full"
          rounded
          @click="openInviteTeammateDialog"
        />
      </div>
    </template>
    <template #empty>
      <EmptyMessage>
        {{ $t('settings.teammates.table.empty', { companyName: company?.name }) }}
      </EmptyMessage>
    </template>
    <Column :header="$t('settings.teammates.table.columns.name')">
      <template #body="{ data }">
        <div class="flex items-center gap-x-4">
          <Avatar shape="circle" :label="initials(data.name)" />

          <section class="flex flex-col gap-y-0.5">
            <span class="color-primary font-semibold">{{ data.name }}</span>
            <span>
              {{ data.email }}
            </span>
          </section>
        </div>
      </template>
    </Column>
    <Column :header="$t('settings.teammates.table.columns.status')">
      <template #body="{ data }">
        <Tag :severity="!data.id ? 'secondary' : 'info'">
          <i :class="!data.id ? 'bi bi-envelope-check-fill' : 'bi bi-check-circle-fill'" />
          <span>
            {{ !data.id ? $t('settings.teammates.table.statuses.invited') : $t('settings.teammates.table.statuses.active') }}
          </span>
        </Tag>
      </template>
    </Column>

    <Column :header="$t('settings.teammates.table.columns.actions')">
      <template #body="{ data }">
        <Button
          icon="pi pi-trash"
          icon-class="font-bold"
          :disabled="userId === data.id"
          rounded size="small"
          severity="danger"
          @click="openDeleteTeammateDialog(data.id ? 'Employer' : 'Invite', data.id || data.inviteId)"
        />
      </template>
    </column>
  </DataTable>
</template>
