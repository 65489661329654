/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeleteEmployerTeammateTypeFromJSON, DeleteEmployerTeammateTypeToJSON, } from './DeleteEmployerTeammateType.js';
/**
 * Check if a given object implements the DeleteEmployerCommandDto interface.
 */
export function instanceOfDeleteEmployerCommandDto(value) {
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function DeleteEmployerCommandDtoFromJSON(json) {
    return DeleteEmployerCommandDtoFromJSONTyped(json, false);
}
export function DeleteEmployerCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'type': DeleteEmployerTeammateTypeFromJSON(json['type']),
        'id': json['id'],
    };
}
export function DeleteEmployerCommandDtoToJSON(json) {
    return DeleteEmployerCommandDtoToJSONTyped(json, false);
}
export function DeleteEmployerCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'type': DeleteEmployerTeammateTypeToJSON(value['type']),
        'id': value['id'],
    };
}
