import type { App, Plugin } from 'vue'
import { computed, inject, ref } from 'vue'
import * as signalR from '@microsoft/signalr'

const COORDINATOR_HUB_KEY = Symbol('CoordinatorHubService') // Injection key

export type CoordinatorHubServiceType = ReturnType<typeof CoordinatorHubService>

function CoordinatorHubService() {
  const connection = ref<signalR.HubConnection | null>(null)
  const isConnected = computed(() => connection.value?.state === signalR.HubConnectionState.Connected)
  
  const reconnectHandlers: Record<string, () => void> = {}
  let reconnectAttempts = 0
  const maxReconnectAttempts = 5

  let lockResolver: (() => void) | null = null

  const acquireWebLock = () => {
    if (lockResolver) {
      console.info('[CoordinatorHub] Lock already acquired.')

      return
    }

    if (navigator && 'locks' in navigator && typeof navigator.locks.request === 'function') {
      const promise = new Promise<void>((resolve) => {
        lockResolver = resolve
      })

      navigator.locks.request('chat_hub_lock', { mode: 'shared' }, () => promise)

      console.info('[CoordinatorHub] Acquired web lock.')
    }
  }

  const releaseWebLock = () => {
    if (lockResolver) {
      lockResolver()
      lockResolver = null

      console.info('[CoordinatorHub] Released web lock.')
    } else {
      console.info('[CoordinatorHub] No web lock to release.')
    }
  }

  /**
   * Initializes the SignalR connection.
   */
  const init = async (accessTokenCallback: () => Promise<string>) => {
    if (connection.value) {
      return
    }

    connection.value = new signalR.HubConnectionBuilder()
      .configureLogging(
        !import.meta.env.PROD
          ? signalR.LogLevel.Trace
          : signalR.LogLevel.None,
      )
      .withUrl(import.meta.env.VITE_APP_COORDINATOR_API_URL + '/hubs/user-events', {
        accessTokenFactory: accessTokenCallback,
      })
      .withAutomaticReconnect([
        500,
        1000,
        2000,
        5000,
        5000,
        5000,
        5000,
        5000, 
      ])
      .build()

    setupEventListeners()
  }

  /**
   * Sets up event listeners for connection state changes.
   */
  const setupEventListeners = () => {
    if (!connection.value) {
      return
    }

    connection.value.onreconnecting(() => {
      console.info('[CoordinatorHub] Reconnecting...')
      acquireWebLock()
    })

    connection.value.onreconnected(() => {
      console.info('[CoordinatorHub] Reconnected successfully.')
      reconnectAttempts = 0

      // Execute all reconnect handlers
      for (const key in reconnectHandlers) {
        reconnectHandlers[key]()
      }
    })

    connection.value.onclose(async () => {
      console.info('[CoordinatorHub] Connection closed.')

      if (reconnectAttempts < maxReconnectAttempts) {
        reconnectAttempts++
        console.info(`[CoordinatorHub] Retrying connection... Attempt ${reconnectAttempts}/${maxReconnectAttempts}`)
        setTimeout(start, 2000)
      } else {
        console.error('[CoordinatorHub] Maximum reconnection attempts reached. Please check your connection.')
        releaseWebLock()
      }
    })
  }

  /**
   * Starts the SignalR connection.
   */
  const start = async () => {
    if (!connection.value) {
      return
    }
    if (connection.value.state === signalR.HubConnectionState.Connected) {
      return
    }

    try {
      acquireWebLock()
      await connection.value.start()
      console.info('[CoordinatorHub] Connection started successfully.')
    } catch (error) {
      console.error('[CoordinatorHub] Failed to start connection:', error)
    }
  }

  /**
   * Stops the SignalR connection.
   */
  const stop = async () => {
    if (!connection.value || connection.value.state === signalR.HubConnectionState.Disconnected) {
      return
    }

    try {
      releaseWebLock()
      await connection.value.stop()
      console.info('[CoordinatorHub] Connection stopped successfully.')
    } catch (error) {
      console.error('[CoordinatorHub] Error stopping connection:', error)
    }
  }

  /**
   * Registers a callback function to be executed when the connection is re-established.
   */
  const onReconnected = (key: string, method: () => void) => {
    reconnectHandlers[key] = method
  }

  /**
   * Registers a method for SignalR events.
   */
  const on = (methodName: string, method: (...args: any[]) => void) => {
    if (!connection.value) {
      return
    }

    connection.value.off(methodName) // Ensure no duplicate listeners
    connection.value.on(methodName, method)
  }

  const retryInvoke = async <T>(times: number, methodName: string, ...args: any[]) => {
    if (times > 3) {
      throw new Error(`[CoordinatorHub] Error invoking method "${methodName}": Maximum retry attempts reached`)
    }

    if (!connection.value || connection.value.state !== signalR.HubConnectionState.Connected) {
      console.info(`[CoordinatorHub] Retry attempt ${times + 1}: Connection not ready`)

      await new Promise((resolve) => setTimeout(resolve, 1000))

      return await retryInvoke(times + 1, methodName, ...args)
    }

    try {
      return await connection.value.invoke<T>(methodName, ...args)
    } catch (error) {
      console.info(`[CoordinatorHub] Error invoking method "${methodName}":`, error)
      await new Promise((resolve) => setTimeout(resolve, 1000))

      return await retryInvoke(times + 1, methodName, ...args)
    }
  }

  /**
   * Invokes a method on the SignalR hub.
   */
  const invoke = async <T>(methodName: string, ...args: any[]): Promise<T> => {
    if (!connection.value) {
      throw new Error('[CoordinatorHub] Connection is not initialized')
    }

    if (connection.value.state !== signalR.HubConnectionState.Connected) {
      return await retryInvoke(0, methodName, ...args)
    }

    try {
      return await connection.value.invoke<T>(methodName, ...args)
    } catch (error) {
      console.error(`[CoordinatorHub] Error invoking method "${methodName}":`, error)
      throw error
    }
  }

  return {
    init,
    start,
    stop,
    isConnected,
    onReconnected,
    on,
    invoke,
    connection,
  }
}

/**
 * Vue Plugin to install the CoordinatorHubService globally.
 */
export default {
  install(app: App) {
    const service = CoordinatorHubService()

    app.provide(COORDINATOR_HUB_KEY, service)
  },
} as Plugin

/**
 * Hook to use the CoordinatorHubService in components.
 */
export function useCoordinatorHubService(): CoordinatorHubServiceType {
  const service = inject(COORDINATOR_HUB_KEY) as CoordinatorHubServiceType
  if (!service) {
    throw new Error('CoordinatorHubService is not provided')
  }

  return service
}
