<script setup lang="ts">
import { PermissionResource } from '@swipe4work/api-client-fetch'
import MatchList from './components/MatchList.vue'

import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  meta: {
    permissionResource: PermissionResource.Match,
  },
})

const topbarStore = useTopbarStore()
const t = useI18n()

topbarStore.setAndClear({
  breadcrumbs: [ { label: t.t('routes.matches') } ],
})
</script>

<template>
  <loading-wrapper>
    <MatchList />
  </loading-wrapper>
</template>
