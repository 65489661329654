import type { App } from 'vue'
import type { Configuration, ResponseError } from '@swipe4work/api-client-fetch'
import { EmployerApi, GeneralApi } from '@swipe4work/api-client-fetch'
import { apiClientFetchConfiguration, coordinatorClientConfig } from '@/configs/client-configs'
import { Configuration as CoordinatorConfiguration, PortalUserApi, UserApi } from '@swipe4work/ai-coordinator-client'

export type EmployerClientType = EmployerApi
export type GeneralClientType = GeneralApi
export type CoordinatorUserClientType = UserApi
export type CoordinatorPortalClientType = PortalUserApi
export type ApiClientTypes = EmployerClientType | GeneralClientType | CoordinatorUserClientType | CoordinatorPortalClientType

export interface ApiClients {
  employerApi: EmployerClientType
  generalApi: GeneralClientType
  coordinatorUserApi: CoordinatorUserClientType
  coordinatorPortalApi: CoordinatorPortalClientType
}

const employerApiInjectionKey = Symbol('employerApi')
const generalApiInjectionKey = Symbol('generalApi')
const coordinatorUserApiInjectionKey = Symbol('coordinatorUserApi')
const coordinatorPortalApiInjectionKey = Symbol('coordinatorPortalApi')

export function useApiClients(): ApiClients {
  return {
    employerApi: useEmployerApiClient(),
    generalApi: useGeneralApiClient(),
    coordinatorUserApi: useCoordinatorUserClient(),
    coordinatorPortalApi: useCoordinatorPortalClient(),
  }
}

export function useEmployerApiClient() {
  const employerApi = inject(employerApiInjectionKey) as (config: Configuration) => EmployerApi

  return employerApi(apiClientFetchConfiguration())
}

export function useGeneralApiClient() {
  const generalApi = inject(generalApiInjectionKey) as (config: Configuration) => GeneralApi

  return generalApi(apiClientFetchConfiguration())
}

export function useCoordinatorUserClient() {
  const coordinatorUserApi = inject(coordinatorUserApiInjectionKey) as (config: CoordinatorConfiguration) => UserApi

  return coordinatorUserApi(coordinatorClientConfig())
}

export function useCoordinatorPortalClient() {
  const coordinatorPortalApi = inject(coordinatorPortalApiInjectionKey) as (config: CoordinatorConfiguration) => PortalUserApi

  return coordinatorPortalApi(coordinatorClientConfig())
}

export async function hasErrorCode(err: ResponseError, errorCode: number): Promise<boolean> {
  // Parse the HTTP response and check if the errorMap has the given error code
  const errors = (await err.response.json()).errors
  if (!errors) {
    return false
  }

  const hasErrorCode = Object.prototype.hasOwnProperty.call(errors, errorCode)

  return hasErrorCode
}

export default {
  install(app: App): void {
    const employerApi = (config: Configuration) => new EmployerApi(config)
    const generalApi = (config: Configuration) => new GeneralApi(config)
    const coordinatorUserApi = (config: CoordinatorConfiguration) => new UserApi(config)
    const coordinatorPortalApi = (config: CoordinatorConfiguration) => new PortalUserApi(config)

    app.provide(employerApiInjectionKey, employerApi)
    app.provide(generalApiInjectionKey, generalApi)
    app.provide(coordinatorUserApiInjectionKey, coordinatorUserApi)
    app.provide(coordinatorPortalApiInjectionKey, coordinatorPortalApi)
  },
}
