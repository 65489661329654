/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobStatusFromJSON, JobStatusToJSON, } from './JobStatus.js';
import { ItemFromJSON, ItemToJSON, } from './Item.js';
import { CharacteristicColorFromJSON, CharacteristicColorToJSON, } from './CharacteristicColor.js';
import { DecimalRangeDtoFromJSON, DecimalRangeDtoToJSON, } from './DecimalRangeDto.js';
import { SkillDtoFromJSON, SkillDtoToJSON, } from './SkillDto.js';
import { DateTimeRangeDtoFromJSON, DateTimeRangeDtoToJSON, } from './DateTimeRangeDto.js';
import { JobClosureReasonFromJSON, JobClosureReasonToJSON, } from './JobClosureReason.js';
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { PeriodicityFromJSON, PeriodicityToJSON, } from './Periodicity.js';
import { JobDescriptionDtoFromJSON, JobDescriptionDtoToJSON, } from './JobDescriptionDto.js';
import { LocationDtoFromJSON, LocationDtoToJSON, } from './LocationDto.js';
import { RangeDtoFromJSON, RangeDtoToJSON, } from './RangeDto.js';
/**
 * Check if a given object implements the GetJobResult interface.
 */
export function instanceOfGetJobResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('locations' in value) || value['locations'] === undefined)
        return false;
    if (!('isRemote' in value) || value['isRemote'] === undefined)
        return false;
    if (!('jobCategoryId' in value) || value['jobCategoryId'] === undefined)
        return false;
    if (!('jobFunctionId' in value) || value['jobFunctionId'] === undefined)
        return false;
    if (!('educationLevels' in value) || value['educationLevels'] === undefined)
        return false;
    if (!('yearsExperience' in value) || value['yearsExperience'] === undefined)
        return false;
    if (!('salary' in value) || value['salary'] === undefined)
        return false;
    if (!('salaryPeriodicity' in value) || value['salaryPeriodicity'] === undefined)
        return false;
    if (!('hoursPerWeek' in value) || value['hoursPerWeek'] === undefined)
        return false;
    if (!('preferredCharacteristicColor' in value) || value['preferredCharacteristicColor'] === undefined)
        return false;
    if (!('skills' in value) || value['skills'] === undefined)
        return false;
    if (!('waitingJobApplicationCount' in value) || value['waitingJobApplicationCount'] === undefined)
        return false;
    if (!('rejectedJobApplicationCount' in value) || value['rejectedJobApplicationCount'] === undefined)
        return false;
    if (!('matchesCount' in value) || value['matchesCount'] === undefined)
        return false;
    if (!('isSubscribed' in value) || value['isSubscribed'] === undefined)
        return false;
    return true;
}
export function GetJobResultFromJSON(json) {
    return GetJobResultFromJSONTyped(json, false);
}
export function GetJobResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'description': JobDescriptionDtoFromJSON(json['description']),
        'createdAt': (new Date(json['createdAt'])),
        'publishedAt': json['publishedAt'] == null ? undefined : (new Date(json['publishedAt'])),
        'visibleBetween': json['visibleBetween'] == null ? undefined : DateTimeRangeDtoFromJSON(json['visibleBetween']),
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'status': JobStatusFromJSON(json['status']),
        'closureReason': json['closureReason'] == null ? undefined : JobClosureReasonFromJSON(json['closureReason']),
        'locations': (json['locations'].map(LocationDtoFromJSON)),
        'isRemote': json['isRemote'],
        'jobCategoryId': json['jobCategoryId'],
        'jobFunctionId': json['jobFunctionId'],
        'educationLevels': (json['educationLevels'].map(ItemFromJSON)),
        'yearsExperience': NullableRangeDtoFromJSON(json['yearsExperience']),
        'salary': DecimalRangeDtoFromJSON(json['salary']),
        'salaryPeriodicity': PeriodicityFromJSON(json['salaryPeriodicity']),
        'hoursPerWeek': RangeDtoFromJSON(json['hoursPerWeek']),
        'preferredCharacteristicColor': CharacteristicColorFromJSON(json['preferredCharacteristicColor']),
        'skills': (json['skills'].map(SkillDtoFromJSON)),
        'waitingJobApplicationCount': json['waitingJobApplicationCount'],
        'rejectedJobApplicationCount': json['rejectedJobApplicationCount'],
        'matchesCount': json['matchesCount'],
        'isSubscribed': json['isSubscribed'],
    };
}
export function GetJobResultToJSON(json) {
    return GetJobResultToJSONTyped(json, false);
}
export function GetJobResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'description': JobDescriptionDtoToJSON(value['description']),
        'createdAt': ((value['createdAt']).toISOString()),
        'publishedAt': value['publishedAt'] == null ? undefined : (value['publishedAt'].toISOString()),
        'visibleBetween': DateTimeRangeDtoToJSON(value['visibleBetween']),
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'status': JobStatusToJSON(value['status']),
        'closureReason': JobClosureReasonToJSON(value['closureReason']),
        'locations': (value['locations'].map(LocationDtoToJSON)),
        'isRemote': value['isRemote'],
        'jobCategoryId': value['jobCategoryId'],
        'jobFunctionId': value['jobFunctionId'],
        'educationLevels': (value['educationLevels'].map(ItemToJSON)),
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'salary': DecimalRangeDtoToJSON(value['salary']),
        'salaryPeriodicity': PeriodicityToJSON(value['salaryPeriodicity']),
        'hoursPerWeek': RangeDtoToJSON(value['hoursPerWeek']),
        'preferredCharacteristicColor': CharacteristicColorToJSON(value['preferredCharacteristicColor']),
        'skills': (value['skills'].map(SkillDtoToJSON)),
        'waitingJobApplicationCount': value['waitingJobApplicationCount'],
        'rejectedJobApplicationCount': value['rejectedJobApplicationCount'],
        'matchesCount': value['matchesCount'],
        'isSubscribed': value['isSubscribed'],
    };
}
