/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Flow = {
    JobConverter: 'JobConverter'
};
export function instanceOfFlow(value) {
    for (const key in Flow) {
        if (Object.prototype.hasOwnProperty.call(Flow, key)) {
            if (Flow[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function FlowFromJSON(json) {
    return FlowFromJSONTyped(json, false);
}
export function FlowFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FlowToJSON(value) {
    return value;
}
export function FlowToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
