import type {
  AddOrUpdateDefaultMessageCommandDto,
  DefaultMessageType,
  GetCompanyFromPortalResult,
  UpdateCompanyFromPortalCommandDto,
} from '@swipe4work/api-client-fetch'
import { useAuthStore } from './auth-store'

export const useCompanyStore = defineStore('company', {
  state: () => ({
    company: undefined as GetCompanyFromPortalResult | undefined,
  }),
  getters: {
    canScout: (state) => {
      if (!state.company) {
        return false
      }

      const authStore = useAuthStore()
      if (!authStore.hasWritePermission('Scout')) {
        return false
      }

      return state.company.scoutCredits > 0
    },
    canMatch: (state) => {
      if (!state.company) {
        return false
      }

      return state.company.canMatch
    },
    matchCredits: state => state.company?.matchCredits ?? 0,
    companyComplete: (state) => {
      return state.company?.logo != null && state.company?.cover != null && state.company?.aboutUs !== ''
    },
    defaultMessages: state => (type: DefaultMessageType) => {
      return state.company?.defaultMessages.filter(m => m.messageType === type) || []
    },
    allJobsSlotsFilled: (state) => {
      return state.company?.hasSubscription && state.company.activeJobs >= (state.company.jobSlots ?? 0)
    },
  },
  actions: {
    async fill(): Promise<void> {
      try {
        await this.fetchCompany()
      } catch (error) {
        console.error(error)
      }
    },
    async getOrFetchCompany(): Promise<GetCompanyFromPortalResult | undefined> {
      if (!this.company) {
        return this.fetchCompany()
      }

      return this.company
    },
    async fetchCompany(): Promise<GetCompanyFromPortalResult | undefined> {
      const response = await this.http.employerApi.getMyCompany()

      this.company = response

      return this.company
    },
    async deleteCompany(): Promise<void> {
      try {
        await this.http.employerApi.deleteMyCompany()
      } catch (e) {
        showApiValidationErrors(this.t.t('companyForm.deleteFailed'), e)
      }
    },
    async updateCompany(company: UpdateCompanyFromPortalCommandDto): Promise<boolean> {
      try {
        await this.http.employerApi.updateMyCompany(company)

        return true
      } catch (e) {
        showApiValidationErrors(this.t.t('companyForm.updateFailed'), e)

        return false
      }
    },
    /* Add or update a default message. If the ID is provided, the default message is updated, else it is added */
    async addOrUpdateDefaultMessage(defaultMessage: AddOrUpdateDefaultMessageCommandDto) {
      try {
        await this.http.employerApi.addOrUpdateDefaultMessage(defaultMessage)

        return true
      } catch (e) {
        showApiValidationErrors(this.t.t('companyForm.addDefaultMessageFailed'), e)

        return false
      }
    },
    async deleteDefaultMessage(defaultMessageId: number) {
      try {
        await this.http.employerApi.deleteDefaultMessage(defaultMessageId)
      } catch (e) {
        showApiValidationErrors(this.t.t('companyForm.deleteDefaultMessageFailed'), e)
      }
    },
  },
})
