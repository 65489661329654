/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FlowRunVersionDto interface.
 */
export function instanceOfFlowRunVersionDto(value) {
    return true;
}
export function FlowRunVersionDtoFromJSON(json) {
    return FlowRunVersionDtoFromJSONTyped(json, false);
}
export function FlowRunVersionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}
export function FlowRunVersionDtoToJSON(json) {
    return FlowRunVersionDtoToJSONTyped(json, false);
}
export function FlowRunVersionDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}
