<script setup lang="ts">
import JobForm from './components/JobForm.vue'

definePage({
  name: '/jobs/edit-ai',
  meta: {
    permissionLevel: 'Write',
    permissionResource: 'Job',
  },
})

const router = useRouter()
const jobFormStore = useJobFormStore()

if (jobFormStore.promptFlowRunId === undefined) {
  router.push({ name: '/jobs/create' })
}
</script>

<template>
  <JobForm :focus-on-job-text="true" />
</template>
