import type { useToast as usePrimeToast } from 'primevue/usetoast'
import { ResolutionTaskManager } from '@/modules/api-clients/async-task-handler/ResolutionTaskManager'
import type { ApiClients, ApiClientTypes } from '@/modules/api-clients/api-clients'
import { ResilienceHandler, ResilienceHandlerOptions } from '@/modules/api-clients/async-task-handler/ResilienceHandler'
import { PromiseResult, RejectedPromiseResult, ResolvedPromiseResult } from '@/utils/safe-resolver'

/** The base class for the async task handler {@link AsyncTaskHandler} */
export class BaseAsyncTaskHandler {
  /** The api client that we want to use for the async task. If not set, it will not provide one  */
  private apiClient?: keyof ApiClients

  /** The resilience handler like for retry on err */
  private resilienceHandler?: ResilienceHandler

  /** The task manager for the success tasks {@link SuccessTasks} */
  protected readonly successTaskManager = new ResolutionTaskManager()

  /** The task manager for the error tasks {@link ErrorTasks} */
  protected readonly errorTaskManager = new ResolutionTaskManager()

  /**
   * @param toast Injected toast, for resolve success and error tasks
   * @param router Injected router for resolve success and error tasks
   * @param apiClients Injected api clients, so we can pass them to the resolve function
   */
  constructor(
    protected readonly toast: ReturnType<typeof usePrimeToast>,
    protected readonly router: ReturnType<typeof useRouter>,
    protected readonly apiClients: ApiClients,
  ) {}

  /**
   * Overload for the resolve function. So we can use the api client that we want to use.
   */
  public async resolve<TClient extends ApiClientTypes, TRes = any>(
    fn: (http: TClient) => Promise<TRes>
  ): PromiseResult<TRes>

  /**
   * Resolve the async task with the given function.
   * @param fn The function to resolve, with the api client if set
   */
  public async resolve<T = any>(
    fn: (http: any) => Promise<T>,
  ): PromiseResult<T> {
    const fnTransformed = fn(this.apiClient ? this.apiClients[this.apiClient] : undefined)

    const [ err, res ] = this.resilienceHandler
      ? await this.resilienceHandler.handle(fnTransformed)
      : await safeResolve(fnTransformed)

    // reset shared props after resolving task, ensures that the next task is not affected by the previous one
    this.apiClient = undefined
    this.resilienceHandler = undefined

    if (err) return this.handleErrorResult(err)

    return this.handleSuccessResult(res)
  }

  private async handleErrorResult(err: Error) : RejectedPromiseResult {
    this.successTaskManager.clear()
    await this.errorTaskManager.run(err, undefined)

    return [ err ]
  }

  private async handleSuccessResult<T>(res: T) : ResolvedPromiseResult<T> {
    this.errorTaskManager.clear()
    await this.successTaskManager.run(undefined, res)

    return [ undefined, res ]
  }

  /**
   * Use a specific client for the next async task.
   * @param client
   */
  public useClient = <T extends keyof ApiClients>(client: T) => {
    this.apiClient = client

    return this
  }

  public useResilienceHandler = (options?: ResilienceHandlerOptions) => {
    this.resilienceHandler = new ResilienceHandler(options || { retry: [ 3, 1000 ] })

    return this
  }
}
