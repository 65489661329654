/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NodeRunDto interface.
 */
export function instanceOfNodeRunDto(value) {
    return true;
}
export function NodeRunDtoFromJSON(json) {
    return NodeRunDtoFromJSONTyped(json, false);
}
export function NodeRunDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'nodeName': json['node_name'] == null ? undefined : json['node_name'],
        'inputData': json['input_data'] == null ? undefined : json['input_data'],
        'outputData': json['output_data'] == null ? undefined : json['output_data'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}
export function NodeRunDtoToJSON(json) {
    return NodeRunDtoToJSONTyped(json, false);
}
export function NodeRunDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'node_name': value['nodeName'],
        'input_data': value['inputData'],
        'output_data': value['outputData'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}
