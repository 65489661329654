/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TrackCategory = {
    Practical: 'Practical',
    Theoretical: 'Theoretical'
};
export function instanceOfTrackCategory(value) {
    for (const key in TrackCategory) {
        if (Object.prototype.hasOwnProperty.call(TrackCategory, key)) {
            if (TrackCategory[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function TrackCategoryFromJSON(json) {
    return TrackCategoryFromJSONTyped(json, false);
}
export function TrackCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TrackCategoryToJSON(value) {
    return value;
}
export function TrackCategoryToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
