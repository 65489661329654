<script setup lang="ts">
import logo from '@/assets/logo.png'
import JobConverterFormText from '@/pages/jobs.ai/components/stepper-items/JobConverterFormText.vue'
import JobConverterDetails from '@/pages/jobs.ai/components/stepper-items/JobConverterDetails.vue'
import JobConverterFormVariables from '@/pages/jobs.ai/components/stepper-items/JobConverterFormVariables.vue'
import { JobFormAction } from '@/pages/jobs/job-form'
import JobConverterResult from '@/pages/jobs.ai/components/stepper-items/JobConverterResult.vue'
import { useFormStore } from '@/components/form/form-store'
import { ActionButtonType } from '@/types/action-type'

definePage({
  name: '/jobs/ai/create',
  path: '/jobs/ai/create',
  meta: {
    layout: 'false',
    permissionLevel: 'Write',
    permissionResource: 'Job',
  },
})

const { isMediumScreen } = useResponsive()
const router = useRouter()
const jobFormStore = useJobFormStore()
const authStore = useAuthStore()
const formStore = useFormStore()
const jobConverterStore = useJobConverterStore()
const stepper = useStepper()

const t = useI18n()

const { companySettings } = storeToRefs(authStore)
const { model } = storeToRefs(jobFormStore)
const { nextStepButton, previousStepButton, currentStep, currentProgress, primeVueStepperOptions } = storeToRefs(stepper)

onBeforeMount(() => {
  jobConverterStore.$reset()
  formStore.$reset()
  jobFormStore.$reset()

  jobFormStore.init(undefined, JobFormAction.Create)
  formStore.init(model, jobFormStore.validationSchema())

  stepper.init(items())
})

const dashboardButton = {
  label: t.t('aiJob.create.header.buttons.dashboard.title'),
  icon: 'pi pi-home',
  outlined: true,
  action: () => router.push({ name: '/' }),
} as ActionButtonType<any>

const items = () => {
  return [
    {
      label: t.t('aiJob.create.header.steps.step1'),
      steps: [
        {
          key: 'jobConverterForm.text',
          component: markRaw(JobConverterFormText),
          headerButtons: [ dashboardButton ],
        },
        {
          key: 'jobConverterForm.variables',
          component: markRaw(JobConverterFormVariables),
          headerButtons: [ dashboardButton ],
        },
      ],
    },
    {
      label: t.t('aiJob.create.header.steps.step2'),
      steps: [
        {
          key: 'jobForm.details',
          component: markRaw(JobConverterDetails),
          headerButtons: [ dashboardButton ],
        },
      ],
    },
    {
      label: t.t('aiJob.create.header.steps.step3'),
      steps: [
        {
          key: 'jobConverter.result',
          component: markRaw(JobConverterResult),
          hideStepper: true,
          hideButtons: true,
          headerButtons: [ dashboardButton ],
        },
      ],
    },
  ]
}

onMounted(() => {
  if (!companySettings.value?.canAccessJobConverter) {
    router.push({ name: '/jobs/create' })

    return
  }
})

jobConverterStore.$subscribe(() => {
  stepper.updateStepGroups(items())
})
</script>

<template>
  <div class="w-full">
    <header>
      <section class="flex items-center justify-between gap-x-5">
        <span class="flex items-center justify-center py-3">
          <router-link :to="{ name: '/' }">
            <img :src="logo" class="h-5 max-[500px]:hidden" alt="logo">
          </router-link>
        </span>

        <Stepper v-if="currentStep?.hideStepper !== true" :value="primeVueStepperOptions.currentValue" class="basis-[35rem]" linear>
          <StepList>
            <Step v-for="step in primeVueStepperOptions.steps" :key="step.value" :value="step.value">
              <span>{{ step.label }}</span>
            </Step>
          </StepList>
        </Stepper>

        <div class="flex items-center justify-center gap-x-1">
          <Button
            v-for="button in currentStep?.headerButtons"
            v-bind="button"
            :label="!isMediumScreen ? '' : button.label"
            :key="button.label"
            size="small"
            rounded
            v-click-async="button.action"
          />
        </div>
      </section>

      <section class="relative mb-5 w-full">
        <ProgressBar :value="currentProgress" :show-value="true" class="rounded-full" />
      </section>
    </header>

    <main>
      <Card class="mx-5 3xl:py-5">
        <template #content>
          <component :is="currentStep?.component" />
        </template>
      </Card>
    </main>

    <footer v-if="currentStep?.hideButtons !== true">
      <Button v-bind="previousStepButton" @click="stepper.previousStep" />

      <Button v-bind="nextStepButton" @click="stepper.nextStep" />
    </footer>
  </div>
</template>

<style lang="scss" scoped>
header {
  @apply fixed h-15 left-0 top-0 w-full px-5 space-y-2.5 \!bg-background-alt z-10;
}

main {
  @apply py-25;
}

footer {
  @apply fixed bottom-0 left-0 w-full flex items-center justify-between bg-background-alt px-5 py-5;
}
</style>
