/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Skills interface.
 */
export function instanceOfSkills(value) {
    return true;
}
export function SkillsFromJSON(json) {
    return SkillsFromJSONTyped(json, false);
}
export function SkillsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'hard': json['hard'] == null ? undefined : json['hard'],
        'soft': json['soft'] == null ? undefined : json['soft'],
        'languages': json['languages'] == null ? undefined : json['languages'],
    };
}
export function SkillsToJSON(json) {
    return SkillsToJSONTyped(json, false);
}
export function SkillsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'hard': value['hard'],
        'soft': value['soft'],
        'languages': value['languages'],
    };
}
