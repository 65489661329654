/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime.js';
import { DeleteFeedbackRequestToJSON, FeedbackDtoFromJSON, PatchRunFeedbackRequestToJSON, PostFeedbackRequestToJSON, UpdateFlowRunRefinedOutputRequestToJSON, } from '../models/index.js';
/**
 *
 */
export class UserApi extends runtime.BaseAPI {
    /**
     */
    async deleteFeedbackRaw(requestParameters, initOverrides) {
        if (requestParameters['deleteFeedbackRequest'] == null) {
            throw new runtime.RequiredError('deleteFeedbackRequest', 'Required parameter "deleteFeedbackRequest" was null or undefined when calling deleteFeedback().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/user/feedback`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteFeedbackRequestToJSON(requestParameters['deleteFeedbackRequest']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteFeedback(deleteFeedbackRequest, initOverrides) {
        await this.deleteFeedbackRaw({ deleteFeedbackRequest: deleteFeedbackRequest }, initOverrides);
    }
    /**
     */
    async patchRunFeedbackRaw(requestParameters, initOverrides) {
        if (requestParameters['patchRunFeedbackRequest'] == null) {
            throw new runtime.RequiredError('patchRunFeedbackRequest', 'Required parameter "patchRunFeedbackRequest" was null or undefined when calling patchRunFeedback().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/user/feedback`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchRunFeedbackRequestToJSON(requestParameters['patchRunFeedbackRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackDtoFromJSON(jsonValue));
    }
    /**
     */
    async patchRunFeedback(patchRunFeedbackRequest, initOverrides) {
        const response = await this.patchRunFeedbackRaw({ patchRunFeedbackRequest: patchRunFeedbackRequest }, initOverrides);
        return await response.value();
    }
    /**
     */
    async postFeedbackRaw(requestParameters, initOverrides) {
        if (requestParameters['postFeedbackRequest'] == null) {
            throw new runtime.RequiredError('postFeedbackRequest', 'Required parameter "postFeedbackRequest" was null or undefined when calling postFeedback().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/user/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostFeedbackRequestToJSON(requestParameters['postFeedbackRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackDtoFromJSON(jsonValue));
    }
    /**
     */
    async postFeedback(postFeedbackRequest, initOverrides) {
        const response = await this.postFeedbackRaw({ postFeedbackRequest: postFeedbackRequest }, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateFlowRunRefinedOutputRaw(requestParameters, initOverrides) {
        if (requestParameters['updateFlowRunRefinedOutputRequest'] == null) {
            throw new runtime.RequiredError('updateFlowRunRefinedOutputRequest', 'Required parameter "updateFlowRunRefinedOutputRequest" was null or undefined when calling updateFlowRunRefinedOutput().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/user/flow-run/refined-output`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFlowRunRefinedOutputRequestToJSON(requestParameters['updateFlowRunRefinedOutputRequest']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateFlowRunRefinedOutput(updateFlowRunRefinedOutputRequest, initOverrides) {
        await this.updateFlowRunRefinedOutputRaw({ updateFlowRunRefinedOutputRequest: updateFlowRunRefinedOutputRequest }, initOverrides);
    }
}
