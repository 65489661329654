import type { Auth, User } from '@firebase/auth'
import {
  sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, updatePassword as updatePasswordFirebase,
} from 'firebase/auth'
import { getCurrentUser } from 'vuefire'
import { AuthProvider, AuthService } from '@/services/auth/auth-service'

/**
 * Firebase authentication service.
 */
export class FirebaseAuthService extends AuthService<User, Auth> {
  authProvider = AuthProvider.Firebase

  /**
   * @inheritDoc
   */
  protected user = async () => this.authClient.currentUser

  constructor(authClient: Auth) {
    super(authClient)
  }

  /**
   * @inheritDoc
   */
  async init() {
    await getCurrentUser()
  }

  /**
   * @inheritDoc
   */
  async getAccessToken() {
    const user = await this.user()
    if (!user) {
      return null
    }

    return await user.getIdToken()
  }

  /**
   * @inheritDoc
   */
  async isAuthenticated() {
    return !!await this.user()
  }

  /**
   * @inheritDoc
   */
  async logout() {
    await this.authClient.signOut()

    return true
  }

  async login(email: string, password: string): Promise<boolean> {
    const response = await signInWithEmailAndPassword(this.authClient, email, password)

    return response !== null
  }

  async loginWithToken(token: string): Promise<boolean> {
    const response = await signInWithCustomToken(this.authClient, token)

    return response !== null
  }

  async sendResetPasswordMail(email: string): Promise<void> {
    await sendPasswordResetEmail(this.authClient, email)
  }

  async updatePassword(email: string, currentPassword: string, newPassword: string): Promise<void> {
    const user = await signInWithEmailAndPassword(this.authClient, email, currentPassword)

    await updatePasswordFirebase(user.user, newPassword)
  }
}
