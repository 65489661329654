<script setup lang="ts">
import type { RouteNamedMap } from 'vue-router/auto-routes'
import { useTopbarStore } from '@/components/app/topbar-store'

export type TypedRouteLocation<T = keyof RouteNamedMap> =
  T extends infer R extends keyof RouteNamedMap
    ? Record<never, never> extends RouteNamedMap[R]['params']
      ? {
        name: R
      }
      : {
        name: R
        params: RouteNamedMap[R]['params']
      }
    : never

definePage({
  path: '/instellingen',
  meta: {
    permissionResource: 'CompanySettings',
  },
  redirect: { name: '/settings/UserProfile' },
})

const t = useI18n()

const topbarStore = useTopbarStore()

topbarStore.setAndClear({
  breadcrumbs: [ { label: t.t('settings.title') } ],
})

const settingsStore = useSettingsStore()

settingsStore.fetchEmployersInCompany()

const route = useRoute()

const items: {
  label: string,
  to: TypedRouteLocation 
}[] = [
  {
    label: t.t('settings.profile'),
    to: { name: '/settings/UserProfile' }, 
  },
  {
    label: t.t('settings.teammates.title'),
    to: { name: '/settings/Teammates' }, 
  },
  {
    label: t.t('settings.defaultMessages.title', 2),
    to: { name: '/settings/DefaultMessages' }, 
  },
]
</script>

<template>
  <Card class="mx-auto max-w-screen-2xl">
    <template #title>
      <Tabs :value="route.name" scrollable>
        <TabList>
          <router-link v-for="tab in items" :key="tab.label" :to="tab.to">
            <Tab :value="tab.to.name">
              <h6>{{ tab.label }}</h6>
            </Tab>
          </router-link>
        </TabList>
      </Tabs>
    </template>
    <template #content>
      <router-view v-slot="{ Component }">
        <loading-wrapper>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </loading-wrapper>
      </router-view>
    </template>
  </Card>
</template>
