/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const RunOrigin = {
    Playground: 'Playground',
    GrowthHacking: 'GrowthHacking',
    EmployerPortal: 'EmployerPortal'
};
export function instanceOfRunOrigin(value) {
    for (const key in RunOrigin) {
        if (Object.prototype.hasOwnProperty.call(RunOrigin, key)) {
            if (RunOrigin[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function RunOriginFromJSON(json) {
    return RunOriginFromJSONTyped(json, false);
}
export function RunOriginFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function RunOriginToJSON(value) {
    return value;
}
export function RunOriginToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
