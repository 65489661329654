<script setup lang="ts">
import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  meta: {
    permissionResource: 'Lead',
  },
})

const upgradeStore = useUpgradeStore()

await upgradeStore.fill()

const t = useI18n()

useTopbarStore().setAndClear({
  breadcrumbs: [ { label: t.t('leads.upgrade.title') } ],
})
</script>

<template>
  <div class="card mb-2">
    <Steps :model="upgradeStore.items" :active-step="upgradeStore.activeIndex" :readonly="true" />
  </div>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>
