/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalaryFromJSON, SalaryToJSON, } from './Salary.js';
import { SectionsFromJSON, SectionsToJSON, } from './Sections.js';
import { HoursFromJSON, HoursToJSON, } from './Hours.js';
import { SkillsFromJSON, SkillsToJSON, } from './Skills.js';
/**
 * Check if a given object implements the JobConverterResponse interface.
 */
export function instanceOfJobConverterResponse(value) {
    return true;
}
export function JobConverterResponseFromJSON(json) {
    return JobConverterResponseFromJSONTyped(json, false);
}
export function JobConverterResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobTitle': json['job_title'] == null ? undefined : json['job_title'],
        'salary': json['salary'] == null ? undefined : SalaryFromJSON(json['salary']),
        'location': json['location'] == null ? undefined : json['location'],
        'hours': json['hours'] == null ? undefined : HoursFromJSON(json['hours']),
        'skills': json['skills'] == null ? undefined : SkillsFromJSON(json['skills']),
        'sections': json['sections'] == null ? undefined : SectionsFromJSON(json['sections']),
    };
}
export function JobConverterResponseToJSON(json) {
    return JobConverterResponseToJSONTyped(json, false);
}
export function JobConverterResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'job_title': value['jobTitle'],
        'salary': SalaryToJSON(value['salary']),
        'location': value['location'],
        'hours': HoursToJSON(value['hours']),
        'skills': SkillsToJSON(value['skills']),
        'sections': SectionsToJSON(value['sections']),
    };
}
