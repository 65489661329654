import type {
  EmployerRole, GetEmployerAccessResult, GetEmployerProfileResult, Item, PermissionDto, PermissionResource,
} from '@swipe4work/api-client-fetch'
import { PermissionLevel } from '@swipe4work/api-client-fetch'
import { useFirebaseAuth } from 'vuefire'
import posthog from 'posthog-js'
import { AuthProvider, AuthService } from '@/services/auth/auth-service'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { MsalAuthService } from '@/services/auth/msal-auth-service'
import { JwtAuthService } from '@/services/auth/jwt-auth-service'

/**
 * State of the authentication process. For overriding the render of router-view in App.vue, so
 * different states as components can be rendered, like within auth guards or stores, without going through the router.
 *
 * By default, the state is `Inactive`. Meaning the router-view will be shown as normal.
 *
 * Examples given:
 * - showing a loading spinner while initializing MSAL,
 * - when user firebase registration fails
 * - when an error like fetching general data fails
 */
export enum AuthState {
  Inactive = 'inactive',

  Initializing = 'initializing',
  ExpiredToken = 'expiredToken',
  InvalidToken = 'invalidToken',
  AuthenticationError = 'authenticationError',
  UnexpectedError = 'unexpectedError',
  ServerNotReachable = 'serverNotReachable',
}

/**
 * useAuthStore - Authentication Store for Multiple Providers
 *
 * This store is designed to provide a standardized interface for managing authentication across the application, regardless of the authentication provider being used.
 * It supports various authentication services such as Microsoft (MSAL), Google Firebase, or JWT-based authentication.
 *
 * ### Generic Functions
 * The auth store includes generic functions commonly used across the application, such as:
 * - `isAuthenticated`: Checks if the user is currently logged in.
 * - `getAccessToken`: Retrieves the access token for the authenticated user.
 * - `logout`: Logs the user out of the application.
 *
 * These functions are consistently available across all authentication providers. For provider-specific functions, such as Firebase's `loginWithPassword` or `resetPassword`,
 * the relevant actions are handled by the individual authentication services and are not part of the generic interface.
 *
 * ### Provider-Specific Functions
 * Each authentication provider may have its own specific methods, which are not applicable to other providers. For instance, Firebase may offer `resetPassword`, but this does not exist for MSAL or JWT.
 * To access these provider-specific methods, use the `getAuthService<T>` method to retrieve the appropriate service instance. This allows you to call provider-specific functions when needed.
 *
 * ### Dynamic Authentication Service
 * - The `getAuthService<T>` method dynamically retrieves and initializes the appropriate authentication service based on the provider (e.g., FirebaseAuthService, MsalAuthService).
 * - If a provider-specific function is required (such as login, password reset, etc.), this method ensures the correct service is used with the right type for type safety.
 *
 * ### Initialization
 * On initialization, the store checks which authentication provider was last active (cached). Depending on the active provider (Firebase, MSAL, JWT), the corresponding service is initialized.
 *
 * Overall, this store provides a seamless way to manage multiple authentication providers while maintaining a unified interface for core authentication operations.
 */
export const useAuthStore = defineStore('auth', {
  state: () => ({
    firebaseAuth: useFirebaseAuth(),

    authState: AuthState.Inactive as AuthState,
    authError: undefined as Error | undefined,
    authService: null as AuthService<unknown, unknown> | null,

    employerAccess: undefined as GetEmployerAccessResult | undefined,
    employerProfile: undefined as GetEmployerProfileResult | undefined,
    managedCompanies: undefined as unknown as Item[],

    companyStore: useCompanyStore(), // DO NOT REMOVE, otherwise the guards will fail and the app will not load
  }),
  getters: {
    // If the user has no employer roles, it's considered an employee user
    isEmployeeUser: state => state.employerAccess?.roles.length === 0,
    isRole: state => (role: EmployerRole) => state.employerAccess?.roles.includes(role) ?? false,
    isFirebaseAuth: state => state.authService instanceof FirebaseAuthService,
    isMsalAuth: state => state.authService instanceof MsalAuthService,
    isJwtAuth: state => state.authService instanceof JwtAuthService,
    isOriginalCompany: (state) => {
      return state.employerProfile?.originalCompanyId === undefined || state.employerProfile?.companyId === state.employerProfile?.originalCompanyId
    },
    canAccessOtherCompanies: (state) => {
      return state.employerAccess?.canAccessOtherCompanies ?? false
    },
    permissions: (state) => {
      return state.employerAccess?.permissions ?? []
    },
    hasFullPermissions(): boolean {
      return this.employerAccess?.permissions.map(p => p.resource).includes('Full') ?? false
    },
    isConferenceUser: state => state.employerAccess?.roles.includes('Conference'),
    isLead: state => state.employerAccess?.roles.includes('Lead'),
    isOnboardingUser: state => state.employerAccess?.roles.includes('ConferenceOnboarding') || state.employerAccess?.roles.includes('CustomerOnboarding'),
    isCustomer: state => state.employerAccess?.roles && state.employerAccess?.roles.length > 0
      && !state.employerAccess?.roles.includes('Conference')
      && !state.employerAccess?.roles.includes('Lead')
      && !state.employerAccess?.roles.includes('ConferenceOnboarding')
      && !state.employerAccess?.roles.includes('CustomerOnboarding'),
    isEmployer: state => state.employerAccess?.roles && state.employerAccess?.roles.length > 0
      && !state.employerAccess?.roles.includes('ConferenceOnboarding')
      && !state.employerAccess?.roles.includes('CustomerOnboarding'),
    hasSubscription: state => state.employerProfile?.hasSubscription,
    hasChatAccess: state => !state.employerAccess?.roles.includes('ConferenceOnboarding') && !state.employerAccess?.roles.includes('CustomerOnboarding'),
    userName: state => state.employerProfile ? `${state.employerProfile.firstName} ${state.employerProfile.lastName}` : '',
    externalId: state => state.employerProfile?.externalId,
    userId: state => state.employerProfile?.id,
    email: state => state.employerProfile?.email,
    activeCompanyId: state => state.employerProfile?.companyId,
    companySettings: state => state.employerProfile?.companySettings,
  },
  actions: {
    async getAuthService<T extends AuthService<unknown, unknown>>(
      Type: { new (authClient: any): T },
      authClient: any = null,
    ): Promise<T> {
      const authService = this.authService
      if (!authService || authService.authProvider !== Type.prototype.authProvider) {
        this.authService = new Type(authClient) // Pass the authClient to the constructor
        AuthService.setAuthProviderInCache(this.authService.authProvider)
        await this.authService.init()
      }

      return this.authService as T
    },
    async init() {
      const activeService = await AuthService.getAuthProviderFromCache()
      if (activeService === AuthProvider.None) {
        return
      }

      switch (activeService) {
        case AuthProvider.Firebase:
          await this.getAuthService(FirebaseAuthService, this.firebaseAuth)
          break
        case AuthProvider.Msal:
          await this.getAuthService(MsalAuthService)
          break
        case AuthProvider.Jwt:
        { await this.getAuthService(JwtAuthService)
          // Check if the token is still valid, if so, override the base view with token invalid
          const isTokenValid = JwtAuthService.validateToken(await this.getAccessToken() || '')
          if (isTokenValid !== true) {
            this.setAuthState(isTokenValid)
          }
          break }
      }
    },
    hasPermission(requiredPermission?: PermissionDto): boolean {
      if (requiredPermission === undefined) {
        return true
      }
      if (requiredPermission.level === PermissionLevel.Read) {
        return this.hasReadPermission(requiredPermission.resource)
      }

      return this.hasWritePermission(requiredPermission.resource)
    },
    // Has permission to read or write
    hasReadPermission(resource: PermissionResource): boolean {
      if (this.employerAccess === undefined) {
        return false
      }

      if (this.hasFullPermissions) {
        return true
      }

      return this.employerAccess.permissions.map(p => p.resource).includes(resource) ?? false
    },
    // Has permission to write
    hasWritePermission(resource: PermissionResource): boolean {
      if (this.employerAccess === undefined) {
        return false
      }

      if (this.hasFullPermissions) {
        return true
      }

      return this.employerAccess.permissions.find(p => p.resource === resource)?.level === PermissionLevel.Write
    },
    async isAuthenticated() {
      if (!this.authService) {
        return false
      }

      const authenticated = await this.authService.isAuthenticated()
      if (!authenticated) {
        return false
      }

      // fetch data or start services if authenticated, but not yet fetched / started, to avoid any potential issues
      await this.initializeAppForUser()

      return authenticated
    },
    async initializeAppForUser() {
      await this.fetchEmployerAccess()

      if (!this.employerAccess) return

      const promises = []

      if (this.hasPermission({
        resource: 'Dashboard',
        level: PermissionLevel.Read, 
      })) {
        promises.push(this.startHubServices())
      }

      if (this.hasPermission({
        resource: 'Dashboard',
        level: PermissionLevel.Read,
      }) && this.employerProfile === undefined) {
        promises.push(this.fetchEmployerProfile())
      }

      if (!this.managedCompanies && this.employerAccess.canAccessOtherCompanies) {
        promises.push(this.fetchManagedCompanies())
      }

      await Promise.all(promises)
    },
    async startHubServices() {
      if (this.chatHubService.isConnected || this.coordinatorHubService.isConnected) {
        return
      }

      const accessToken = await this.getAccessToken()
      if (accessToken === '') {
        return
      }

      this.chatHubService.init(async () => await this.getAccessToken() || '')
      this.coordinatorHubService.init(async () => await this.getAccessToken() || '')

      await this.chatHubService.start()
      await this.coordinatorHubService.start()
    },
    async requestRefreshToken() {
      if (!this.authService || this.authService.authProvider !== AuthProvider.Jwt) {
        return false
      }

      const authService = await this.getAuthService(JwtAuthService)

      const token = await authService.getAccessToken()
      if (!token) {
        return false
      }

      await this.http.employerApi.requestLoginLink(token)

      return true
    },
    async getAccessToken() {
      if (!this.authService) {
        return null
      }

      return await this.authService.getAccessToken()
    },
    async getEmployerAccess() {
      if (this.employerAccess) {
        return this.employerAccess
      }

      return await this.fetchEmployerAccess()
    },
    async fetchEmployerAccess() {
      const [ err, res ] = await safeResolve(this.http.employerApi.getEmployerAccess())
      if (err) {
        console.error('Error fetching employer access', err)

        return undefined
      }

      posthog.setPersonProperties({
        'is-lead': this.isLead,
      })

      return this.employerAccess = res
    },
    async fetchEmployerProfile() {
      const [ err, res ] = await safeResolve(this.http.employerApi.getProfile())
      if (err) {
        console.error('Error fetching employer profile', err)

        return undefined
      }

      posthog.identify(res.externalId)
      posthog.group('company', `company:${res.companyId}`, { name: res.companyName })

      return this.employerProfile = res
    },
    async fetchManagedCompanies() {
      const [ err, res ] = await safeResolve(this.http.employerApi.listManagedCompanies())
      if (err) {
        console.error('Error fetching managed companies', err)
        this.managedCompanies = []

        return
      }

      this.managedCompanies = res.companies
    },
    async updateManagedCompany(companyId: number) {
      const [ err ] = await safeResolve(this.http.employerApi.updateManagedCompany(companyId))
      if (err) {
        this.toast.error(this.t.t('admin.updateManagedCompany.failed'), { timeout: undefined })

        return
      }

      // Force a refresh to make sure everything is reloaded
      await this.router.replace('/')
      window.location.reload()
    },
    async resetManagedCompany() {
      const [ err ] = await safeResolve(this.http.employerApi.resetManagedCompany())
      if (err) {
        this.toast.error(this.t.t('admin.resetManagedCompany.failed'), { timeout: undefined })

        return
      }

      // Force a refresh to make sure everything is reloaded
      await this.router.replace('/')
      window.location.reload()
    },
    async logout(forceReload: boolean = false) {
      await this.chatHubService.stop()

      this.employerProfile = undefined
      this.employerAccess = undefined
      posthog.reset()

      await this.init()

      if (!this.authService) {
        return
      }

      await this.authService.logout()

      AuthService.setAuthProviderInCache(AuthProvider.None)

      this.authService = null
      if (forceReload) window.location.reload()
    },
    setAuthState(state: AuthState) {
      this.authState = state
    },
  },
})
