import { PromiseResult } from '@/utils/safe-resolver'
import { ResponseError } from '@swipe4work/ai-coordinator-client'

export type ResilienceHandlerOptions = {
  retry: [number, number] // [maxRetries, retryInterval]
}

/**
 * A resilience handler for handling http requests. Currently only supports retries.
 */
export class ResilienceHandler {
  /** The statuses that we want to ignore, since they are not retryable */
  private ignoreStatuses = [
    400,
    401,
    403,
    404,
    405,
    429,
    500,
    502,
    503,
    504, 
  ]

  private retries = 0

  constructor(private options: ResilienceHandlerOptions) {}

  public async handle<T>(fn: Promise<T>): PromiseResult<T> {
    const [ maxRetries, retryInterval ] = this.options.retry

    const [ err, res ] = await safeResolve<T>(fn)
    if (!err) return [ err, res ]

    if (err instanceof ResponseError && this.ignoreStatuses.includes(err.response.status)) {
      return [ err ]
    }

    if (this.retries >= maxRetries) {
      return [ err ]
    }

    this.retries++

    await new Promise((resolve) => setTimeout(resolve, retryInterval))

    return this.handle(fn)
  }
}