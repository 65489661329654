<script setup lang="ts">
import arrowRight from '@/assets/icons/arrow-right.svg'

definePage({
  name: '/conference.onboarding/introduction',
  path: 'welkom',
  meta: {
    layout: 'false',
  },
})

const conferenceOnboardingStore = useConferenceOnboardingStore()
const { employerOnboarding, companyName } = storeToRefs(conferenceOnboardingStore)
</script>

<template>
  <section class="mb-7.5 max-w-lg space-y-3">
    <h1>
      {{ $t('conference.onboarding.intro.title', [employerOnboarding?.invite?.firstName]) }}
    </h1>

    <p>
      {{ $t('conference.onboarding.intro.body', [companyName]) }}
    </p>
  </section>

  <Button primary rounded class="max-w-md p-2" @click="conferenceOnboardingStore.goToPage(1)">
    <div class="flex items-center justify-between pl-2">
      {{ $t('conference.onboarding.intro.continueLabel') }}
      <span class="right-1 h-8 w-8 flex items-center justify-center rounded-full bg-warning transition-all duration-300 ease-in-out group-hover:right-0 group-hover:h-full group-hover:w-full">
        <img :src="arrowRight" alt="arrow-right" class="h-5 w-5 transition-colors duration-300 ease-in-out">
      </span>
    </div>
  </Button>
  <a href="https://www.swipe4work.nl/blog/probeer-swipe4work-tijdens-de-promotiedagen" target="_blank" class="max-w-md">
    <Button primary outlined rounded class="w-full p-2">
      <div class="flex items-center justify-between pl-2">
        {{ $t('conference.onboarding.intro.moreInfoLabel') }}
        <span class="right-1 h-8 w-8 flex items-center justify-center rounded-full bg-warning transition-all duration-300 ease-in-out group-hover:right-0 group-hover:h-full group-hover:w-full">
          <img :src="arrowRight" alt="arrow-right" class="h-5 w-5 transition-colors duration-300 ease-in-out">
        </span>
      </div>
    </Button>
  </a>
</template>
