import { createI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import type { UserModule } from '@/types'
import 'dayjs/locale/nl'

/*
 * Import i18n resources
 * https://vitejs.dev/guide/features.html#glob-import
 */

const messages = Object.fromEntries(
  Object.entries(import.meta.glob<{ default: any }>('../../locales/*.y(a)?ml', { eager: true })).map(([ key, value ]) => {
    const yaml = key.endsWith('.yaml')

    return [ key.slice(14, yaml ? -5 : -4), value.default ]
  }),
)

export const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  locale: 'nl',
  messages,
  numberFormats: {
    nl: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
  },
})

export const install: UserModule = (app) => {
  dayjs.locale('nl')
  app.use(i18n)
}
