/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CompanySettings interface.
 */
export function instanceOfCompanySettings(value) {
    if (!('canAccessJobConverter' in value) || value['canAccessJobConverter'] === undefined)
        return false;
    return true;
}
export function CompanySettingsFromJSON(json) {
    return CompanySettingsFromJSONTyped(json, false);
}
export function CompanySettingsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'canAccessJobConverter': json['canAccessJobConverter'],
    };
}
export function CompanySettingsToJSON(json) {
    return CompanySettingsToJSONTyped(json, false);
}
export function CompanySettingsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'canAccessJobConverter': value['canAccessJobConverter'],
    };
}
