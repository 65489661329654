import * as Sentry from '@sentry/vue'
import {
  breadcrumbsIntegration,
  browserTracingIntegration,
  captureConsoleIntegration,
  dedupeIntegration,
  httpClientIntegration,
  httpContextIntegration,
} from '@sentry/vue'
import type { UserModule } from '@/types'

export const install: UserModule = (app, router) => {
  const isDevelopment = import.meta.env.VITE_APP_ENV === 'development'

  if (import.meta.env.VITE_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
      app,
      debug: isDevelopment,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN_URL,
      tunnel: import.meta.env.VITE_APP_SENTRY_TUNNEL,
      integrations: [
        browserTracingIntegration({ router }),
        captureConsoleIntegration({
          levels: [ 'error' ], 
        }),
        httpContextIntegration(),
        httpClientIntegration(),
        breadcrumbsIntegration({
          console: true,
          dom: true,
          fetch: true,
          history: true,
          sentry: true,
          xhr: true,
        }),
        dedupeIntegration(),

      ],
      ignoreErrors: [
        'Unable to preload CSS',
        'Failed to fetch dynamically imported module', // vite issue with new releases and old chunks: https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
        'Importing a module script failed',
        'error loading dynamically imported module',
      ],
      tracePropagationTargets: [
        'localhost',
        'portal.swipe4work.com',
        'portal.test.swipe4work.com',
        'api.swipe4work.com',
        'api.test.swipe4work.com',
        /^\//,
      ],
      
      release: `${import.meta.env.VITE_GIT_SHA}`,
      /*
       * Set tracesSampleRate to 1.0 to capture 100%
       * of transactions for performance monitoring.
       * We recommend adjusting this value in production
       */
      tracesSampleRate: import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE,
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      sendDefaultPii: true,
    })
  }
}
