import type { ToastMessageOptions } from 'primevue'
import type { RouteLocationRaw } from 'vue-router'
import { BaseAsyncTaskHandler } from '@/modules/api-clients/async-task-handler/AsyncTaskHandler.Base'
import type { ResolutionTaskManager } from '@/modules/api-clients/async-task-handler/ResolutionTaskManager'

/** These are generic tasks that are available for all resolution tasks types (success and error). */
interface ResolutionTasks<out T> {
  notifyWithToast: (summary: string, detail?: string, options?: ToastMessageOptions) => T
  redirectTo: (route: RouteLocationRaw) => T
  redirectBack: () => T
  endUse: () => AsyncTaskHandler
}

/** These are the success tasks that are available for the success resolution tasks. {@link AsyncTaskHandler.} */
interface SuccessTasks extends Omit<ResolutionTasks<SuccessTasks>, 'redirectTo'> {
  callback: <T>(cb: (res: T) => Promise<any> | any) => SuccessTasks
  redirectTo: <T>(cb: (res: T) => RouteLocationRaw) => SuccessTasks
}

interface ErrorTasks extends ResolutionTasks<ErrorTasks> {
  callback: (cb: (err: any) => Promise<any> | any) => ErrorTasks
}

/**
 * A class that handles async tasks.
 * It is used to chain tasks like showing a toast message after a successful API call.
 * Actions that we use commonly we can chain them here instead of writing them in every component.
 *
 * Seperated from the base, because that's the core logic and this is the extension,
 * where fluent resolution tasks are added. So we have a clear separation of concerns.
 */
export class AsyncTaskHandler extends BaseAsyncTaskHandler {
  /**
   * Use this function to add success tasks.
   */
  public useOnSucceedTasks = (): SuccessTasks => {
    const taskManager = this.successTaskManager

    const tasks: Partial<SuccessTasks> = {
      callback: <T>(cb: (res: T) => Promise<void>) => {
        taskManager.add('callback', (_, res) => cb(res))

        return tasks as SuccessTasks
      },
      redirectTo: <T>(cb: (res: T) => RouteLocationRaw) => {
        taskManager.add('redirectTo', (_, res) => this.router.push(cb(res)))

        return tasks as SuccessTasks
      },
    }

    return this.appendResolutionTasks<SuccessTasks>(taskManager, tasks as SuccessTasks)
  }

  /**
   * Use this function to add error tasks.
   */
  public useOnErrorTasks = () => {
    const taskManager = this.errorTaskManager

    const tasks: Partial<ErrorTasks> = {
      callback: (cb: (err: any) => Promise<any> | any) => {
        taskManager.add('callback', (err) => cb(err))

        return tasks as ErrorTasks
      },
    }

    return this.appendResolutionTasks<ErrorTasks>(taskManager, tasks as ErrorTasks)
  }

  /**
   * Append shared resolution tasks to the tasks object.
   * @param taskManager The task manager to add the tasks to
   * @param tasks The tasks obj to append on, if we dont do this, it will not be fluent, and return ResolutionTasks instead of T
   */
  private appendResolutionTasks = <T = { [k: string]: any }>(taskManager: ResolutionTaskManager, tasks: T): T => {
    /** The current instance of the AsyncTaskHandler, so we can use it in the tasks; fluent */
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const instance = this

    // @ts-ignore
    tasks.notifyWithToast = (summary: string, detail?: string, options?: ToastMessageOptions) => {
      taskManager.add('notifyWithToast', (err) => {
        instance.toast.add({
          summary,
          detail,
          severity: err ? 'error' : 'success',
          closable: true,
          life: 5000,
          ...options,
        })
      })

      return tasks
    }

    // @ts-ignore
    if (tasks.redirectTo === undefined) {
      // @ts-ignore
      tasks.redirectTo = (route: RouteLocationRaw) => {
        taskManager.add('redirectTo', () => {
          instance.router.push(route)
        })

        return tasks
      }
    }

    // @ts-ignore
    tasks.redirectBack = () => {
      taskManager.add('redirectBack', () => {
        instance.router.back()
      })

      return tasks
    }

    // @ts-ignore
    tasks.endUse = () => instance

    return tasks
  }
}
