/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RunOriginFromJSON, RunOriginToJSON, } from './RunOrigin.js';
/**
 * Check if a given object implements the Ownership interface.
 */
export function instanceOfOwnership(value) {
    return true;
}
export function OwnershipFromJSON(json) {
    return OwnershipFromJSONTyped(json, false);
}
export function OwnershipFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'origin': json['origin'] == null ? undefined : RunOriginFromJSON(json['origin']),
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'teamId': json['team_id'] == null ? undefined : json['team_id'],
    };
}
export function OwnershipToJSON(json) {
    return OwnershipToJSONTyped(json, false);
}
export function OwnershipToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'origin': RunOriginToJSON(value['origin']),
        'user_id': value['userId'],
        'team_id': value['teamId'],
    };
}
