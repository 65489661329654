<script setup lang="ts">
import type { Ref } from 'vue'
import { object, string } from 'zod'
import { useToast } from 'vue-toastification'
import { useForm } from '@/modules/form'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { captureException } from '@sentry/vue'

definePage({
  name: '/reset-password',
  path: '/reset-password',
})

const authStore = useAuthStore()
const toast = useToast()
const t = useI18n()
const router = useRouter()
const resetPasswordModel = reactive({
  email: '',
})

const resetPasswordScheme = object({
  email: string().min(1),
})

const form = useForm(resetPasswordModel, resetPasswordScheme)
const resetPasswordSucceeded: Ref<boolean | undefined> = ref(undefined)

async function submit() {
  const firebaseAuthService = new FirebaseAuthService(authStore.firebaseAuth!)

  resetPasswordSucceeded.value = undefined
  const entity = form.validate()
  if (!entity) {
    return
  }

  try {
    await firebaseAuthService.sendResetPasswordMail(entity.email)

    toast.success(t.t('auth.resetPassword.success'))
    await router.push({ name: '/login' })
  } catch (error) {
    captureException(error)
    resetPasswordSucceeded.value = false
  }
}
</script>

<template>
  <AuthLayoutCard>
    <template #title>
      {{ $t("auth.resetPassword.title") }}
    </template>

    <template #message>
      {{ $t("auth.resetPassword.message") }}
    </template>

    <form class="w-100% flex flex-col items-center justify-center gap-y-5 px-5">
      <AuthFormGroup :label="$t('email')" :error="resetPasswordSucceeded === false ? $t('auth.resetPassword.failed') : undefined">
        <InputText
          id="email"
          v-model.trim="resetPasswordModel.email"
          class="w-100%"
          required="true"
          type="email"
          autocomplete="email"
          autofocus
          :class="{ 'p-invalid': form.hasError('resetPasswordModel.email') }"
        />
      </AuthFormGroup>

      <Button v-click-async="submit" type="submit" rounded :label="$t('auth.resetPassword.submit')" icon-pos="left" icon="bi bi-send" />
    </form>

    <div class="flex flex-col items-center justify-center gap-y-1 text-sm">
      <span>
        {{ $t('auth.resetPassword.wrongPage') }}
        <BasicTextLinkInternal :to="{ name: '/login' }" :label="$t('auth.resetPassword.backToLogin')" class="font-normal!" />
      </span>
    </div>
  </AuthLayoutCard>
</template>
