/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { instanceOfFlowRunVersionDto, FlowRunVersionDtoFromJSONTyped, FlowRunVersionDtoToJSON, } from './FlowRunVersionDto.js';
export function FlowRunDtoOriginFromJSON(json) {
    return FlowRunDtoOriginFromJSONTyped(json, false);
}
export function FlowRunDtoOriginFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    if (instanceOfFlowRunVersionDto(json)) {
        return FlowRunVersionDtoFromJSONTyped(json, true);
    }
    return {};
}
export function FlowRunDtoOriginToJSON(json) {
    return FlowRunDtoOriginToJSONTyped(json, false);
}
export function FlowRunDtoOriginToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    if (instanceOfFlowRunVersionDto(value)) {
        return FlowRunVersionDtoToJSON(value);
    }
    return {};
}
