import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { EmployerRole } from '@swipe4work/api-client-fetch'
import type { RouteNamedMap } from 'vue-router/auto-routes'
import { Guard, type GuardResult, GuardResultType } from '@/guards/abstract-guard'

/**
 * Type for the redirect policy
 *
 * true: Redirect to the route
 * false: Proceed to the next guard / policy
 */
type RedirectPolicy = (to: RouteLocationNormalized, authStore: ReturnType<typeof useAuthStore>) => boolean | Promise<boolean>

/**
 * Guard to check if the user's account type is allowed for the route
 *
 * The user is already authenticated at this point
 */
export default class AuthorizationAccountTypeGuard extends Guard {
  protected label = 'AuthorizationAccountTypeGuard'

  protected async check(to: RouteLocationNormalized): Promise<GuardResult | void> {
    const roles = await this.authStore.getEmployerAccess()
    if (roles === undefined) {
      return GuardResultType.Skip
    }

    // Redirect policies for the routes
    const redirectPolicies: Partial<Record<keyof RouteNamedMap, RedirectPolicy>> = {
      '/employee': this.employeeRedirectPolicy,
      '/onboarding/register': this.customerOnboardingEmployerRedirectPolicy,
      '/conference/onboarding': this.conferenceOnboardingEmployerRedirectPolicy,

      '/company/': this.createLeadCompanyRedirectPolicy,
    }

    for (const [ routeName, policy ] of Object.entries(redirectPolicies)) {
      const isAlreadyRedirected = to.name === routeName
      if (isAlreadyRedirected) {
        this.logger.debug(`Already redirected to ${policy.name}`)

        continue
      }

      const shouldRedirect = await policy(to, this.authStore)
      if (!shouldRedirect) {
        continue
      }

      this.logger.debug(`Redirecting condition met, account type not allowed, redirecting  to ${policy.name}`)

      return { name: routeName } as RouteLocationRaw
    }
  }

  private employeeRedirectPolicy: RedirectPolicy = (_, authStore) => {
    return authStore.isEmployeeUser
  }

  private customerOnboardingEmployerRedirectPolicy: RedirectPolicy = (to, authStore) => {
    return !to.meta.allowedRoles?.includes(EmployerRole.CustomerOnboarding) && authStore.isRole(EmployerRole.CustomerOnboarding)
  }

  private conferenceOnboardingEmployerRedirectPolicy: RedirectPolicy = (to, authStore) => {
    return !to.meta.allowedRoles?.includes(EmployerRole.ConferenceOnboarding) && authStore.isRole(EmployerRole.ConferenceOnboarding)
  }

  /**
   * For internal users that are creating lead companies enforce the company creation flow before proceeding to other routes
   * @param to
   * @param authStore
   */
  private createLeadCompanyRedirectPolicy: RedirectPolicy = async (to, authStore) => {
    if (!authStore.isMsalAuth) {
      return false
    }

    const companyStore = useCompanyStore()

    await companyStore.getOrFetchCompany()

    return authStore.isMsalAuth && !companyStore.companyComplete
  }
}
