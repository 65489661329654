/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipFromJSON, OwnershipToJSON, } from './Ownership.js';
import { FlowRunVersionDtoFromJSON, FlowRunVersionDtoToJSON, } from './FlowRunVersionDto.js';
import { RunStatusFromJSON, RunStatusToJSON, } from './RunStatus.js';
import { FlowRunDtoOriginFromJSON, FlowRunDtoOriginToJSON, } from './FlowRunDtoOrigin.js';
import { NodeRunDtoFromJSON, NodeRunDtoToJSON, } from './NodeRunDto.js';
import { FeedbackDtoFromJSON, FeedbackDtoToJSON, } from './FeedbackDto.js';
import { FlowFromJSON, FlowToJSON, } from './Flow.js';
/**
 * Check if a given object implements the FlowRunDto interface.
 */
export function instanceOfFlowRunDto(value) {
    return true;
}
export function FlowRunDtoFromJSON(json) {
    return FlowRunDtoFromJSONTyped(json, false);
}
export function FlowRunDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'flow': json['flow'] == null ? undefined : FlowFromJSON(json['flow']),
        'status': json['status'] == null ? undefined : RunStatusFromJSON(json['status']),
        'inputData': json['input_data'] == null ? undefined : json['input_data'],
        'outputData': json['output_data'] == null ? undefined : json['output_data'],
        'owner': json['owner'] == null ? undefined : OwnershipFromJSON(json['owner']),
        'feedbacks': json['feedbacks'] == null ? undefined : (json['feedbacks'].map(FeedbackDtoFromJSON)),
        'nodeRuns': json['node_runs'] == null ? undefined : (json['node_runs'].map(NodeRunDtoFromJSON)),
        'origin': json['origin'] == null ? undefined : FlowRunDtoOriginFromJSON(json['origin']),
        'versions': json['versions'] == null ? undefined : (json['versions'].map(FlowRunVersionDtoFromJSON)),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}
export function FlowRunDtoToJSON(json) {
    return FlowRunDtoToJSONTyped(json, false);
}
export function FlowRunDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'flow': FlowToJSON(value['flow']),
        'status': RunStatusToJSON(value['status']),
        'input_data': value['inputData'],
        'output_data': value['outputData'],
        'owner': OwnershipToJSON(value['owner']),
        'feedbacks': value['feedbacks'] == null ? undefined : (value['feedbacks'].map(FeedbackDtoToJSON)),
        'node_runs': value['nodeRuns'] == null ? undefined : (value['nodeRuns'].map(NodeRunDtoToJSON)),
        'origin': FlowRunDtoOriginToJSON(value['origin']),
        'versions': value['versions'] == null ? undefined : (value['versions'].map(FlowRunVersionDtoToJSON)),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}
