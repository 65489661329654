<script setup lang="ts">
import CompanyForm from './components/CompanyForm.vue'
import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  meta: {
    permissionResource: 'CompanyProfile',
  },
})

const topbarStore = useTopbarStore()
const t = useI18n()

topbarStore.setAndClear({
  breadcrumbs: [ { label: t.t('routes.company') } ],
})
</script>

<template>
  <loading-wrapper>
    <CompanyForm />
  </loading-wrapper>
</template>
