<script setup lang="ts">
import { EmployerRole } from '@swipe4work/api-client-fetch'

definePage({
  name: '/onboarding',
  path: '/onboarding',
  meta: {
    layout: 'onboarding',
    allowedRoles: [ EmployerRole.CustomerOnboarding ],
  },
  redirect: '/onboarding/register',
})

const onboardingStore = useOnboardingStore()

await onboardingStore.fill()

const { activeIndex } = storeToRefs(onboardingStore)
</script>

<template>
  <div>
    <div class="card mb-2">
      <Steps :model="onboardingStore.items" :active-step="activeIndex" :readonly="true" />
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
