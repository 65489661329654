/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Sections interface.
 */
export function instanceOfSections(value) {
    return true;
}
export function SectionsFromJSON(json) {
    return SectionsFromJSONTyped(json, false);
}
export function SectionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'opening': json['opening'] == null ? undefined : json['opening'],
        'duties': json['duties'] == null ? undefined : json['duties'],
        'requirements': json['requirements'] == null ? undefined : json['requirements'],
        'benefits': json['benefits'] == null ? undefined : json['benefits'],
        'closing': json['closing'] == null ? undefined : json['closing'],
    };
}
export function SectionsToJSON(json) {
    return SectionsToJSONTyped(json, false);
}
export function SectionsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'opening': value['opening'],
        'duties': value['duties'],
        'requirements': value['requirements'],
        'benefits': value['benefits'],
        'closing': value['closing'],
    };
}
