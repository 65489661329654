/**
 * The result of a promise, with the error or the data. The code became too cluttered with generics / types
 * To make it more readable, we created this type alias.
 */
export type PromiseResult<T> = Promise<[undefined, T] | [Error]>
export type RejectedPromiseResult = PromiseResult<never>
export type ResolvedPromiseResult<T> = PromiseResult<T>

/**
 * Safe resolve a promise, so we can catch the error and return it. without try catch
 * @param fn
 */
export const safeResolve = <T>(fn: Promise<T>): PromiseResult<T> => fn
  .then(data => [ undefined, data ] as [undefined, T])
  .catch(err => [ err ])

export const safeResolveParseResult = <T>(fn: Promise<T>, fallback: T): Promise<T> => fn
  .then(data => data)
  .catch(() => fallback)
