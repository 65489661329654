<script setup lang="ts">
import type { CreateCompanyForEmployerInvoiceDto } from '@swipe4work/api-client-fetch'
import nProgress from 'nprogress'
import BillingForm from './components/BillingForm.vue'

definePage({
  path: 'billing',
  name: '/onboarding/billing',
  meta: {
    layout: 'false',
  },
})

const onboardingStore = useOnboardingStore()
const { selectedPaymentMethod, invoiceModel } = storeToRefs(onboardingStore)

function prevPage() {
  onboardingStore.goToPage(2)
}

if (!selectedPaymentMethod.value) {
  onboardingStore.goToPage(2)
}

const submitting = ref(false)

async function submit(model: CreateCompanyForEmployerInvoiceDto, hasAcceptedTermsAndConditions: boolean) {
  nProgress.start()
  submitting.value = true
  invoiceModel.value = model
  const success = await onboardingStore.createCompany(hasAcceptedTermsAndConditions)
  if (success) {
    await onboardingStore.finishOnboarding()
  }
  nProgress.done()
  submitting.value = false
}
</script>

<template>
  <BillingForm :submitting="submitting" @next-page="submit" @prev-page="prevPage" />
</template>
