/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Salary interface.
 */
export function instanceOfSalary(value) {
    return true;
}
export function SalaryFromJSON(json) {
    return SalaryFromJSONTyped(json, false);
}
export function SalaryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'periodicity': json['periodicity'] == null ? undefined : json['periodicity'],
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
    };
}
export function SalaryToJSON(json) {
    return SalaryToJSONTyped(json, false);
}
export function SalaryToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'periodicity': value['periodicity'],
        'min': value['min'],
        'max': value['max'],
    };
}
