import type { ZodRawShape, z } from 'zod'
/**
 *
 * @param model model to validate
 * @param scheme validation scheme
 * @returns messages to create forms and validate forms
 */
export function useForm<T extends object, TModel extends ZodRawShape>(
  model: T,
  // ZodObject or a ZodObject that is refined. See CompanyForm for a refine example
  scheme: z.ZodEffects<z.ZodObject<TModel>> | z.ZodObject<TModel>,
) {
  const _validationErrors = ref(null as z.ZodError | null)

  // whether the form has been submitted at least once
  const submitted = ref(false)

  // true while form is submitting
  const submitting = ref(false)

  // returns whether the form is valid
  const isValid = computed(() => {
    return _validationErrors.value == null
  })

  // returns whether a form element is invalid
  const hasError = (...path: (string | number)[]): boolean => {
    if (!_validationErrors.value || !submitted.value || submitting.value) {
      return false
    }

    return _validationErrors.value.issues.findIndex(p => p.path.join('.') === path.join('.')) !== -1
  }

  const errorMessage = (...path: (string | number)[]): string => {
    if (!hasError || !_validationErrors.value) {
      return ''
    }

    const idx = _validationErrors.value.issues.findIndex(p => p.path.join('.') === path.join('.'))
    if (idx === -1) {
      return ''
    }

    return _validationErrors.value.issues[idx].message
  }

  const reset = () => {
    submitted.value = false
    submitting.value = false
    _validationErrors.value = null
  }

  /**
   * Validate the form model with the validation scheme.
   * @returns type used to represent the output of the form, can be used in generated OpenAPI code, or null if model does not comply with scheme
   */
  const validate = () => {
    _validationErrors.value = null
    const validation = scheme.safeParse(model)

    submitted.value = true
    if (validation.success) {
      return validation.data
    } 
    _validationErrors.value = validation.error

    submitting.value = false
  }

  // watch for changes in the model and revalidate
  watch(model, async () => {
    if (submitted.value) {
      await validate()
    }
  })

  return {
    submitted,
    submitting,
    isValid,
    validate,
    hasError,
    errorMessage,
    reset,
  }
}
