/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CharacteristicColorFromJSON, CharacteristicColorToJSON, } from './CharacteristicColor.js';
import { ExperienceLevelFromJSON, ExperienceLevelToJSON, } from './ExperienceLevel.js';
import { TrackCategoryFromJSON, TrackCategoryToJSON, } from './TrackCategory.js';
/**
 * Check if a given object implements the JobConverterRequest interface.
 */
export function instanceOfJobConverterRequest(value) {
    return true;
}
export function JobConverterRequestFromJSON(json) {
    return JobConverterRequestFromJSONTyped(json, false);
}
export function JobConverterRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobText': json['job_text'] == null ? undefined : json['job_text'],
        'companyInfo': json['company_info'] == null ? undefined : json['company_info'],
        'jobCategoryCode': json['job_category_code'] == null ? undefined : json['job_category_code'],
        'characteristicColor': json['characteristic_color'] == null ? undefined : CharacteristicColorFromJSON(json['characteristic_color']),
        'trackCategory': json['track_category'] == null ? undefined : TrackCategoryFromJSON(json['track_category']),
        'experienceLevel': json['experience_level'] == null ? undefined : ExperienceLevelFromJSON(json['experience_level']),
    };
}
export function JobConverterRequestToJSON(json) {
    return JobConverterRequestToJSONTyped(json, false);
}
export function JobConverterRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'job_text': value['jobText'],
        'company_info': value['companyInfo'],
        'job_category_code': value['jobCategoryCode'],
        'characteristic_color': CharacteristicColorToJSON(value['characteristicColor']),
        'track_category': TrackCategoryToJSON(value['trackCategory']),
        'experience_level': ExperienceLevelToJSON(value['experienceLevel']),
    };
}
