type ResolutionTask = (err: any, res: any) => unknown | Promise<unknown>

/**
 * A manager for resolution tasks.
 * For fluent Promise task chaining. Like adding a task to show a toast message after a successful API call.
 * Or redirecting to a page after a successful API call.
 */
export class ResolutionTaskManager {
  private tasks: { [k: string]: ResolutionTask } = {}

  public add = (k: string, o: ResolutionTask) => this.tasks[k] = o

  public remove = (k: string) => delete this.tasks[k]

  public clear = () => this.tasks = {}

  public async run(err: any, res: any) {
    const handlers = Object.values(this.tasks).map(
      (handler) => {
        const result = handler(err, res)

        return Promise.resolve(result) // Convert result to Promise if it isn't already
      },
    )

    await Promise.all(handlers)

    this.tasks = {}

    return this
  }
}
