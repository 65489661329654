import { useToast as usePrimeToast } from 'primevue/usetoast'
import { useApiClients } from '@/modules/api-clients/api-clients'
import { AsyncTaskHandler } from '@/modules/api-clients/async-task-handler/AsyncTaskHandler'

/*
 * Use this function to create a new instance of the AsyncTaskHandler
 * This is necessary for the Vue 3 Composition API, otherwise the dependency injection will not work.
 */
export const useAsyncTaskHandler = () => new AsyncTaskHandler(
  usePrimeToast(),
  useRouter(),
  useApiClients(),
)
