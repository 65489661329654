/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const RunStatus = {
    Pending: 'Pending',
    Processing: 'Processing',
    Failed: 'Failed',
    Completed: 'Completed'
};
export function instanceOfRunStatus(value) {
    for (const key in RunStatus) {
        if (Object.prototype.hasOwnProperty.call(RunStatus, key)) {
            if (RunStatus[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function RunStatusFromJSON(json) {
    return RunStatusFromJSONTyped(json, false);
}
export function RunStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function RunStatusToJSON(value) {
    return value;
}
export function RunStatusToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
