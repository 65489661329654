/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Hours interface.
 */
export function instanceOfHours(value) {
    return true;
}
export function HoursFromJSON(json) {
    return HoursFromJSONTyped(json, false);
}
export function HoursFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'isRemote': json['is_remote'] == null ? undefined : json['is_remote'],
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
    };
}
export function HoursToJSON(json) {
    return HoursToJSONTyped(json, false);
}
export function HoursToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'is_remote': value['isRemote'],
        'min': value['min'],
        'max': value['max'],
    };
}
