/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobConverterRequestFromJSON, JobConverterRequestToJSON, } from './JobConverterRequest.js';
/**
 * Check if a given object implements the EnqueueJobConverterInPortalRequest interface.
 */
export function instanceOfEnqueueJobConverterInPortalRequest(value) {
    return true;
}
export function EnqueueJobConverterInPortalRequestFromJSON(json) {
    return EnqueueJobConverterInPortalRequestFromJSONTyped(json, false);
}
export function EnqueueJobConverterInPortalRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'teamId': json['team_id'] == null ? undefined : json['team_id'],
        'jobConverterRequest': json['job_converter_request'] == null ? undefined : JobConverterRequestFromJSON(json['job_converter_request']),
    };
}
export function EnqueueJobConverterInPortalRequestToJSON(json) {
    return EnqueueJobConverterInPortalRequestToJSONTyped(json, false);
}
export function EnqueueJobConverterInPortalRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'team_id': value['teamId'],
        'job_converter_request': JobConverterRequestToJSON(value['jobConverterRequest']),
    };
}
