/**
 * Log levels for .env configuration
 */
export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export interface Logger {
  info: (message: string) => void
  warn: (message: string) => void
  error: (message: string) => void
  debug: (message: string) => void
}

// Utility function to get current timestamp
const getTimestamp = () => new Date().toISOString()

/**
 * Log level priority for .env configuration
 */
const logLevelPriority: { [key in LogLevel]: number } = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
}

/**
 * Custom logger hook
 *
 * @param domain
 * @param subDomain
 */
export const useLogger = (domain: string, subDomain?: string): Logger => {
  const loggerEnabled = import.meta.env.VITE_APP_LOGGER_ENABLED === 'true'
  const configuredLogLevel: LogLevel = (import.meta.env.VITE_APP_LOG_LEVEL || 'error') as LogLevel

  const shouldLog = (messageLevel: LogLevel) => {
    return (
      loggerEnabled
      && logLevelPriority[messageLevel] >= logLevelPriority[configuredLogLevel]
    )
  }

  const logMessage = (level: LogLevel, message: string) => {
    if (!shouldLog(level)) {
      return
    }

    const formattedMessage = subDomain !== undefined
      ? `[${getTimestamp()}] [${domain}] [${subDomain}] ${level.toUpperCase()}: ${message}`
      : `[${getTimestamp()}] [${domain}] ${level.toUpperCase()}: ${message}`

    switch (level) {
      case 'debug':
        // eslint-disable-next-line no-console
        console.debug(formattedMessage)
        break
      case 'info':
         
        console.info(formattedMessage)
        break
      case 'warn':
        console.warn(formattedMessage)
        break
      case 'error':
        console.error(formattedMessage)
        break
    }
  }

  return {
    info: (message: string) => logMessage('info', message),
    warn: (message: string) => logMessage('warn', message),
    error: (message: string) => logMessage('error', message),
    debug: (message: string) => logMessage('debug', message),
  }
}
