/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackCategoryFromJSON, TrackCategoryToJSON, } from './TrackCategory.js';
/**
 * Check if a given object implements the ListEducationLevelsWithTrackCategoryEducationLevelDto interface.
 */
export function instanceOfListEducationLevelsWithTrackCategoryEducationLevelDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('trackCategory' in value) || value['trackCategory'] === undefined)
        return false;
    return true;
}
export function ListEducationLevelsWithTrackCategoryEducationLevelDtoFromJSON(json) {
    return ListEducationLevelsWithTrackCategoryEducationLevelDtoFromJSONTyped(json, false);
}
export function ListEducationLevelsWithTrackCategoryEducationLevelDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'trackCategory': TrackCategoryFromJSON(json['trackCategory']),
    };
}
export function ListEducationLevelsWithTrackCategoryEducationLevelDtoToJSON(json) {
    return ListEducationLevelsWithTrackCategoryEducationLevelDtoToJSONTyped(json, false);
}
export function ListEducationLevelsWithTrackCategoryEducationLevelDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'trackCategory': TrackCategoryToJSON(value['trackCategory']),
    };
}
