<script setup lang="ts">
import Button from 'primevue/button'
import { useConfirm } from 'primevue/useconfirm'
import { nativeEnum, number, object, string } from 'zod'
import type { DefaultMessageDto } from '@swipe4work/api-client-fetch'
import { DefaultMessageType } from '@swipe4work/api-client-fetch'
import { useForm } from '@/modules/form'

definePage({
  name: '/settings/DefaultMessages',
  path: '/instellingen/standaard-berichten',
  meta: {
    layout: 'false',
  },
})

const t = useI18n()

const confirm = useConfirm()
const companyStore = useCompanyStore()

companyStore.fetchCompany()

const { company } = storeToRefs(companyStore)

const messageTypeOptions = Object.keys(DefaultMessageType).map(key => ({
  label: t.t(`settings.defaultMessages.types.${key}.label`),
  value: key,
  description: t.t(`settings.defaultMessages.types.${key}.description`),
}))

const baseModel = {
  id: undefined as number | undefined,
  messageType: DefaultMessageType.StartMatch as DefaultMessageType,
  title: '',
  body: '',
}

const model = reactive({ ...baseModel })

const form = useForm(model, object({
  id: number().optional(),
  messageType: nativeEnum(DefaultMessageType),
  title: string().min(1),
  body: string().min(1),
}))

const defaultMessageDialogVisible = ref(false)

async function submitDefaultMessage() {
  const entity = form.validate()
  if (form.isValid && entity !) {
    const success = await companyStore.addOrUpdateDefaultMessage(entity)
    if (success) {
      hideDialog()

      await companyStore.fetchCompany()
    }
  }
}

function deleteDefaultMessage(defaultMessageId: number) {
  confirm.require({
    header: t.t('confirm'),
    message: t.t('settingsPage.confirmDeleteDefaultMessage'),
    acceptLabel: t.t('yes'),
    rejectLabel: t.t('no'),
    accept: async () => {
      await companyStore.deleteDefaultMessage(defaultMessageId)
      await companyStore.fetchCompany()
    },
  })
}

function showDefaultMessageDialog(defaultMessage?: DefaultMessageDto) {
  form.reset()

  defaultMessageDialogVisible.value = true

  model.id = defaultMessage?.id ?? undefined
  model.messageType = defaultMessage?.messageType ?? DefaultMessageType.StartMatch
  model.title = defaultMessage?.title ?? ''
  model.body = defaultMessage?.body ?? ''
}

function hideDialog() {
  defaultMessageDialogVisible.value = false
}
</script>

<template>
  <DataTable :value="company?.defaultMessages" striped-rows>
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-2">
          <h5 class="max-sm:hidden">
            {{ $t('settings.defaultMessages.table.title', { companyName: company?.name }) }}
          </h5>

          <PopoverHover>
            {{ $t('settings.defaultMessages.info.banner') }}
          </PopoverHover>
        </div>
        <Button
          :label="$t('settings.defaultMessages.add')"
          icon="bi bi-plus-lg"
          class="max-sm:w-full"
          rounded
          @click="showDefaultMessageDialog()"
        />
      </div>
    </template>

    <template #empty>
      <EmptyMessage>
        {{ $t('settings.defaultMessages.table.empty') }}
      </EmptyMessage>
    </template>
    <Column header="Bericht">
      <template #body="defaultMessage">
        <div class="max-w-lg flex flex-col gap-y-1">
          <span class="text-sm font-semibold">{{ defaultMessage.data.title }}</span>
          <span class="text-sm">{{ defaultMessage.data.body }}</span>
        </div>
      </template>
    </Column>
    <Column field="messageType" :header="$t('settings.defaultMessages.form.messageType.label')">
      <template #body="defaultMessage">
        <Tag>
          {{ $t(`settings.defaultMessages.types.${defaultMessage.data.messageType}.label`) }}
        </Tag>
      </template>
    </Column>
    <Column header="Acties">
      <template #body="defaultMessage">
        <div class="flex gap-x-2">
          <Button
            v-if="!defaultMessage.data.isPublic"
            size="small" rounded
            severity="warn"
            icon="pi pi-pencil"
            @click="showDefaultMessageDialog(defaultMessage.data)"
          />
          <Button
            v-if="!defaultMessage.data.isPublic"
            rounded
            size="small"
            severity="danger"
            icon="pi pi-trash"
            @click="deleteDefaultMessage(defaultMessage.data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <!-- Add Default Message Dialog -->
  <Dialog
    v-model:visible="defaultMessageDialogVisible"
    :modal="true"
    :header="$t('settings.defaultMessages.form.title')"
    class="min-w-2xl"
    :closable="false"
    :draggable="false"
    :dismissable-mask="true"
    @hide="hideDialog"
  >
    <div class="mb-6">
      <label for="reportingPeriodicity">{{ $t("settings.defaultMessages.form.messageType.label") }}</label>
      <Select
        v-model="model.messageType"
        :options="messageTypeOptions"
        option-value="value"
        option-label="label"
        class="w-100%"
        scroll-height="400px"
        :class="{ 'p-invalid': form.hasError('messageType') }"
      >
        <template #option="slotProps">
          <div class="flex items-center">
            <PopoverHover class="mr-2">
              {{ slotProps.option.description }}
            </PopoverHover>
            <span>{{ slotProps.option.label }}</span>
          </div>
        </template>
      </Select>
      <small v-if="form.hasError('title')" class="color-red-700">{{ form.errorMessage("messageType") }}</small>
    </div>
    <div class="mb-6">
      <label for="title"> {{ $t("title") }} </label>
      <InputText
        id="title"
        v-model.trim="model.title"
        class="w-100%"
        required
        :class="{ 'p-invalid': form.hasError('title') }"
      />
      <small v-if="form.hasError('title')" class="color-red-700">{{ form.errorMessage("title") }}</small>
    </div>
    <div>
      <label for="message"> {{ $t("message") }} </label>
      <Textarea
        id="message"
        v-model.trim="model.body"
        class="w-100%"
        rows="10"
        :auto-resize="true"
        required
        :class="{ 'p-invalid': form.hasError('body') }"
      />
      <small v-if="form.hasError('body')" class="color-red-700">{{ form.errorMessage("body") }}</small>
    </div>

    <template #footer>
      <Button :label="$t('cancel')" variant="outlined" @click="hideDialog" />
      <Button v-click-async="submitDefaultMessage" :label="$t('save')" autofocus />
    </template>
  </Dialog>
</template>
