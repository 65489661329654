import type { RouteLocationNormalized } from 'vue-router'
import type { GuardResult } from '@/guards/abstract-guard'
import { Guard, GuardResultType } from '@/guards/abstract-guard'
import type { AuthProvider } from '@/services/auth/auth-service'
import { JwtAuthService } from '@/services/auth/jwt-auth-service'
import { MsalAuthService } from '@/services/auth/msal-auth-service'
import { AuthState } from '@/stores/auth-store'

interface RedirectLoginQueryParams {
  redirectToken: string
  authProvider: keyof typeof AuthProvider
  forceLogout: boolean
}

interface RedirectWithProviderOptions {
  validateToken: (token: string) => AuthState | true
  handleLoginWithToken: (token: string) => Promise<boolean>
  continueWithAuthFlow?: () => boolean
}

/**
 * Guard to handle redirect login.
 */
export default class RedirectLoginGuard extends Guard {
  protected label = 'RedirectLoginGuard'

  private authProviderKey = 'auth-provider'

  private redirectTokenKey = 'redirect-token'

  // Force logout is for S4w.Tools to generate accounts for developers.
  private forceLogoutKey = 'force-logout'

  private authRedirectProviders: Partial<Record<keyof typeof AuthProvider, RedirectWithProviderOptions>>
    = {
      Jwt: {
        validateToken: JwtAuthService.validateToken,
        handleLoginWithToken: async (token: string) => {
          const authService = await this.authStore.getAuthService(JwtAuthService)

          return authService.loginWithToken(token)
        },
        continueWithAuthFlow: () => {
          /*
           * to prevent account auth provider conversion issues (JWT -> Firebase) when the user is already authenticated
           * skip the logout, since employer with JWT external_id does not exists anymore
           */
          if (this.authStore.isFirebaseAuth) {
            return false
          }

          return !this.authStore.isJwtAuth
        },
      },
      Msal: {
        validateToken: MsalAuthService.validateToken,
        handleLoginWithToken: async (token: string) => {
          const authService = await this.authStore.getAuthService(MsalAuthService)

          return authService.loginWithToken(token)
        },
        continueWithAuthFlow: () => {
          return !this.authStore.isMsalAuth
        },
      },
    }

  protected async check(to: RouteLocationNormalized): Promise<GuardResult | void> {
    const queryParams = this.parseQueryParams(to)
    if (!queryParams) {
      return GuardResultType.Next
    }

    const { redirectToken, authProvider, forceLogout } = queryParams

    if (!this.authRedirectProviders[authProvider]) {
      const message = `Auth provider not implemented: ${authProvider}`

      this.logger.error(message)
      this.authStore.setAuthState(AuthState.Initializing)

      return {
        name: '/auth-state',
      }
    }

    const redirectProvider = this.authRedirectProviders[authProvider] as RedirectWithProviderOptions

    try {
      await this.handleRedirect(redirectToken, redirectProvider, forceLogout)
    } catch (error) {
      console.error(error)
      this.authStore.setAuthState(AuthState.AuthenticationError)
    }

    if (this.authStore.authState !== AuthState.Inactive) {
      return {
        name: '/auth-state',
      }
    }

    // Remove the auth query params from the URL
    const queryWithoutRedirectParams = {
      ...to.query,
      [this.authProviderKey]: undefined,
      [this.redirectTokenKey]: undefined,
      [this.forceLogoutKey]: undefined,
    }

    return {
      ...to,
      query: queryWithoutRedirectParams,
    }
  }

  private parseQueryParams(to: RouteLocationNormalized): RedirectLoginQueryParams | null {
    const redirectToken = to.query[this.redirectTokenKey] as string | undefined
    const authProvider = to.query[this.authProviderKey] as keyof typeof AuthProvider | undefined
    const forceLogout = to.query[this.forceLogoutKey] === 'true'

    if (!redirectToken || !authProvider) {
      return null
    }

    return {
      redirectToken,
      authProvider,
      forceLogout, 
    }
  }

  private async handleRedirect(token: string, options: RedirectWithProviderOptions, forceLogout: boolean) {
    const {
      validateToken,
      handleLoginWithToken,
      continueWithAuthFlow,
    } = options

    const validationResult = validateToken(token)
    if (validationResult !== true) {
      this.logger.error(`Token validation failed: ${validationResult}`)
      this.authStore.setAuthState(validationResult)

      return
    }

    if (!forceLogout && continueWithAuthFlow && !continueWithAuthFlow()) {
      this.logger.debug('Skipping redirect login, condition not met')

      return
    }

    this.authStore.setAuthState(AuthState.Initializing)

    await this.handleLogout()

    const success = await this.handleLogin(() => handleLoginWithToken(token))

    this.authStore.setAuthState(success
      ? AuthState.Inactive
      : AuthState.AuthenticationError,
    )
  }

  private async handleLogout(): Promise<void> {
    const isAuthenticated = await this.authStore.isAuthenticated()
    if (!isAuthenticated) {
      this.logger.debug('User is not authenticated, skipping logout')

      return
    }

    this.logger.debug('User is already authenticated, logging out')

    await this.authStore.logout()
  }

  private async handleLogin(loginCallback: () => Promise<boolean>): Promise<boolean> {
    let success = false

    try {
      this.logger.debug('Logging in with token')
      success = await loginCallback()
    } catch (error) {
      this.logger.error('Login failed')
      console.error(error)
    }

    return success
  }
}
