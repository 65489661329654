/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime.js';
import { EnqueueJobConverterInPortalRequestToJSON, FlowRunDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class PortalUserApi extends runtime.BaseAPI {
    /**
     */
    async enqueueJobConverterInPortalRaw(requestParameters, initOverrides) {
        if (requestParameters['enqueueJobConverterInPortalRequest'] == null) {
            throw new runtime.RequiredError('enqueueJobConverterInPortalRequest', 'Required parameter "enqueueJobConverterInPortalRequest" was null or undefined when calling enqueueJobConverterInPortal().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/portal-user/enqueue-job-converter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnqueueJobConverterInPortalRequestToJSON(requestParameters['enqueueJobConverterInPortalRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FlowRunDtoFromJSON(jsonValue));
    }
    /**
     */
    async enqueueJobConverterInPortal(enqueueJobConverterInPortalRequest, initOverrides) {
        const response = await this.enqueueJobConverterInPortalRaw({ enqueueJobConverterInPortalRequest: enqueueJobConverterInPortalRequest }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getFlowRunInPortalRaw(requestParameters, initOverrides) {
        if (requestParameters['runId'] == null) {
            throw new runtime.RequiredError('runId', 'Required parameter "runId" was null or undefined when calling getFlowRunInPortal().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // ApiKey authentication
        }
        const response = await this.request({
            path: `/portal-user/flow-run/{run_id}`.replace(`{${"run_id"}}`, encodeURIComponent(String(requestParameters['runId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FlowRunDtoFromJSON(jsonValue));
    }
    /**
     */
    async getFlowRunInPortal(runId, initOverrides) {
        const response = await this.getFlowRunInPortalRaw({ runId: runId }, initOverrides);
        return await response.value();
    }
}
