/* tslint:disable */
/* eslint-disable */
/**
 * Portal Client API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FeedbackDto interface.
 */
export function instanceOfFeedbackDto(value) {
    return true;
}
export function FeedbackDtoFromJSON(json) {
    return FeedbackDtoFromJSONTyped(json, false);
}
export function FeedbackDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'userEmail': json['user_email'] == null ? undefined : json['user_email'],
        'isLike': json['is_like'] == null ? undefined : json['is_like'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}
export function FeedbackDtoToJSON(json) {
    return FeedbackDtoToJSONTyped(json, false);
}
export function FeedbackDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'user_id': value['userId'],
        'user_email': value['userEmail'],
        'is_like': value['isLike'],
        'comment': value['comment'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}
