import { useRouter } from 'vue-router'
import type { App, Plugin } from 'vue'
import { useToast } from 'vue-toastification'
import type { ApiClients } from '@/modules/api-clients/api-clients'
import {
  useCoordinatorPortalClient, useCoordinatorUserClient, useEmployerApiClient, useGeneralApiClient,
} from '@/modules/api-clients/api-clients'
import { i18n } from '@/modules/i18n'
import type { ChatHubServiceType } from '@/modules/signalr-hubs/chat-hub'
import { useChatHubService } from '@/modules/signalr-hubs/chat-hub'
import { CoordinatorHubServiceType, useCoordinatorHubService } from '@/modules/signalr-hubs/coordinator-hub'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    http: ApiClients
    router: ReturnType<typeof useRouter>
    t: ReturnType<typeof useI18n>
    toast: ReturnType<typeof useToast>
    chatHubService: ChatHubServiceType
    coordinatorHubService: CoordinatorHubServiceType
    asyncTaskHandler: ReturnType<typeof useAsyncTaskHandler>
  }
}

const pinia = createPinia()

pinia.use(({ store }) => {
  store.http = {
    generalApi: useGeneralApiClient(),
    employerApi: useEmployerApiClient(),
    coordinatorUserApi: useCoordinatorUserClient(),
    coordinatorPortalApi: useCoordinatorPortalClient(),
  }

  store.chatHubService = useChatHubService()
  store.coordinatorHubService = useCoordinatorHubService()
  store.asyncTaskHandler = useAsyncTaskHandler()

  store.router = useRouter()
  store.t = i18n.global as unknown as ReturnType<typeof useI18n>
  store.toast = useToast()
})

export default {
  install(app: App) {
    app.use(pinia)
  },
} as Plugin
